import { querySenaoMembership } from '@/api/member-registration';
import { renderState } from '@/helpers/vuex';
import store from '@/store/index';
import { openModal } from '@/helpers/modal';

export const checkMembership = () => {
  // set a default value
  store.commit('RegisterSenaoMembership/setIsUserMember', false);
  const response = (res) => {
    const { data } = res;
    const userIsMember = 1;
    if (data.code === userIsMember) {
      store.commit('RegisterSenaoMembership/setIsUserMember', true);
      openModal('GoLoginModal');
    }
  };
  const orderInfoMobile = renderState('OrderInfo', 'mobilePhone');
  querySenaoMembership(orderInfoMobile).then(response);
};
