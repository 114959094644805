<template>
  <modal componentName="CreditcardModal" modalSizeCssClass="sm" :isModalStatic="true" :isHideFooter="true">
    <template v-slot:body>
      <img class="spacing bottom md" src="/images/service/credit-card-cvc-demo.png" alt="信用卡示意圖" />
      <div class="text-center">美國運通卡為正面4碼</div>
      <img class="spacing top sm" src="/images/service/credit-card-cvc-demo-amex.png" alt="信用卡示意圖" />
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/common-modal.vue';

export default {
  name: 'cvc-modal',
  components: {
    Modal,
  },
};
</script>
