<template>
  <div class="modal_box">
    <h3 class="align center">【 註冊資料 】</h3>
    <p class="align center">
      請同意成為神腦生活會員
      <br />
      系統將以您提供的手機號碼
      <b class="price">{{ mobilePhone }}</b>
      註冊成為神腦會員
    </p>
    <register-info :name-err-msg="'姓名'" :email-err-msg="'E-Mail'" :sex-err-msg="'性別'"></register-info>
    <div class="manual-canvas">
      <h5 class="title">會員服務條款及隱私權政策</h5>
      <div class="content">
        <privacy-terms-content></privacy-terms-content>
      </div>
      <p class="spacing top sm">
        <input
          class="checkbox"
          id="check-agree-terms"
          type="checkbox"
          v-model="isCheckedAgreement"
          @change="checkCheckboxStatus" />
        <label class="checkbox-label" for="check-agree-terms"> 我已閱讀並同意上述會員服務條款及隱私權政策 </label>
      </p>
      <div class="css-td" style="border: 0; padding: 0">
        <p class="error" v-if="validateErrorMsg">
          {{ validateErrorMsg }}
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default" data-dismiss="modal" @click="closeModal">不同意</button>
      <button class="btn btn-secondary" href="javascript:void(0);" @click="verifyAndGetMemberRegistration">同意</button>
    </div>
  </div>
</template>

<script>
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { commitMutation, getState, renderState } from '@/helpers/vuex';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';
import RegisterInfo from '@/components/common/modals/register-info-modal/register-info.vue';
import PrivacyTermsContent from '@/components/common/modals/register-info-modal/privacy-terms-content.vue';

const closeModal = function () {
  return this.$emit('cancel');
};

const verifyAndGetMemberRegistration = function () {
  this.checkCheckboxStatus();
  this.$validator.validateAll().then((result) => {
    if (result && this.isCheckedAgreement) {
      sendGA4sEvent('sign_up_join', createRegistrationItem('註冊資料填寫完成', 'cart'));
      this.$emit('get-member-registration');
    }
  });
};

export default {
  name: 'step1-member-registration',
  mixins: [memberRegistrationProcess],
  props: {
    mobilePhone: {
      type: String,
      default: '',
    },
  },
  components: {
    RegisterInfo,
    PrivacyTermsContent,
  },
  computed: {
    step: getState('RegisterSenaoMembership', 'step'),
    validateErrorMsg: getState('RegisterSenaoMembership', 'validateErrorMsg'),
    isCheckedAgreement: {
      get() {
        return renderState('RegisterSenaoMembership', 'isDisabledCheckedAgreement');
      },
      set(value) {
        commitMutation('RegisterSenaoMembership', 'isDisabledCheckedAgreement', value);
      },
    },
  },
  methods: {
    verifyAndGetMemberRegistration,
    closeModal,
  },
};
</script>
