<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      手機
    </div>
    <div class="css-td">
      <input
        v-if="isNotMasked"
        v-model="mobilePhone"
        v-validate="'required|mobile-phone'"
        :name="name"
        :disabled="isRtype"
        class="input-lg"
        maxlength="10"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedMobile" class="input-lg" type="text" @focus="isNotMasked = true" />
      <span v-if="isShow" class="sub spacing">
        {{ postfix }}
      </span>
      <p class="error">{{ errors.first(name) }}</p>
    </div>
  </div>
</template>

<script>
import { compose, not } from 'ramda';
import { createAdvComputed, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';
import { index } from '@/components/desktop/step1/common/constant/common-mobilephone.constant';
import { isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';

/** 0 元訂單不顯示 [請填信用卡持卡人姓名] */
const isShow = compose(not, isZeroOrder);

export default {
  name: 'recipient-mobilephone',
  inject: ['$validator'],
  props: [
    'name',
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
    'isRtype',
  ],
  computed: {
    /** [手機] */
    mobilePhone: createAdvComputed(index.mobilePhone),
    /** 手機提示資訊: 這裡會是門取手機與付款人資訊的手機共用，故需判斷目前是什麼情境 */
    postfix() {
      const paymentType = renderState('PaymentInfo', 'payment');
      const isCreditCard = [99, 100, 199].indexOf(paymentType) > -1;
      const isFromCardInfo = this.module === 'CardInfo';
      return isCreditCard && isFromCardInfo ? '(請填信用卡持卡人手機)' : '';
    },
    isShow,
  },
  created() {
    this.setIsNotMaskedByValue(this.mobilePhone);
  },
  setup(props) {
    const { maskedMobile, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedMobile, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
