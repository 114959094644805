<template>
  <div class="total-item">
    <span class="label">本次訂單交易實付金額：</span>
    <span class="price">{{ instantAmount | dollar }}</span>
  </div>
</template>

<script>
import { pipe, when, gt, __, prop } from 'ramda';
import { dollar } from '@/helpers/format/dollar';
import { getGetter, dispatchAction, applyMutation, commitMutation } from '@/helpers/vuex';
import { paymentStatusLUT } from '@/components/desktop/step1/amount-detail/constant/real-amount.constant';

/** 只要 PaymentInfo.installmentStatus 為 0 (not visible)，則不受 [isInstallment] 控制 */
/** 如果 PaymentInfo.installmentStatus 大於 0 (visible)，則受 [isInstallment] 控制 */
/** 如果 [isIntallment] 為 1，表示 [本次實付金額] 為 (可分期)，則 [付款方式] 顯示 [信用卡分期] */
/** 如果 [isIntallment] 為 0，表示 [本次實付金額] 為 (不可分期)，則 [付款方式] 不顯示 [信用卡分期]
 *  如果 [isIntallment] 為 2，表示此賣場提供可分期，但 call getBest 取回內容為空陣列，則 [付款方式] 顯示 [信用卡分期] 但 user 不能選取 */
const mutateInstallment = (val) =>
  when(gt(__, 0), applyMutation('PaymentInfo', 'setInstallment', prop(val, paymentStatusLUT)));

/** 若 [是否可分期] 更改，表示 [使用福利金] 或 [宅配]/[門市取貨] 有更改，導致 [是否可分期] 更改
 *  installmentStatus: @return 0,1,2
 *  */
const watchIsInstallment = (val) => pipe(getGetter('PaymentInfo', 'installmentStatus'), mutateInstallment(val));

const watch = {
  /** 每次 [本次實付金額] 因為 [折抵] 或 [配送方式] 改變 ; 可即時驗證是否為0元訂單
   *  For 折扣可抵用的情境，切換折扣當 instantAmount > 0 就呼叫 [取得信用卡分期] API
   *  installmentStatus : 可否分期？ @return {Number}
   *  isInstantAmount 的值將對應 realShipAmounts[index] 裡的 index ; [0] 未使用折扣物件 */
  instantAmount() {
    const isInstantAmount = this.instantAmount > 0 ? 0 : 1;
    commitMutation('AmountDetail', 'isInstantAmount', isInstantAmount);
    return isInstantAmount === 0 ? dispatchAction('InstallmentInfo', 'getInstallments') : false;
  },
  isInstallment() {
    return watchIsInstallment();
  },
};

export default {
  name: 'real-amount',
  filters: {
    dollar,
  },
  computed: {
    /** 本次實付金額 */
    instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
    /** 可否分期 @return {Number} 0, 1 */
    isInstallment: getGetter('AmountDetail', 'isInstallment'),
  },
  watch,
};
</script>
