<template>
  <div class="css-tr">
    <div class="css-th align right w-f9" :class="isAmexClass">
      <b class="notice">*</b>
      卡片背面末三碼
      <small v-if="isAmex">(美國運通卡為正面4碼)</small>
    </div>
    <div class="css-td">
      <input
        id="cvc"
        type="text"
        name="cvc"
        autocomplete="off"
        class="input-sm js-cvc-input"
        placeholder="背面末三至四碼"
        maxlength="4"
        v-model="cvcValue"
        v-validate="{ required: isCvcRequired, 'cvc-length': true }"
        @input="onInput"
        @focus="focusCardCVC"
        @blur="lostFocusCardCVC" />
      <a href="javascript:void(0);" @click="openModal('CreditcardModal')" class="underline inverted fn-sm"> 說明圖 </a>
      <p v-if="errors.has('cvc')" class="error">必填，必須是數字</p>
    </div>
    <creditcard-modal></creditcard-modal>
  </div>
</template>

<script>
import { openModal } from '@/helpers/modal';
import { toggleState, commitMutation, createComputed, renderGetter } from '@/helpers/vuex';
import { getCardType } from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';
import CreditcardModal from '@/components/desktop/step1/payment-method/common/child/creditcard-modal.vue';

/** 信用卡卡面 focus 背面末三碼位置 */
const focusCardCVC = function () {
  this.$emit('creditCardFocus', 'CVC');
};

/** 信用卡卡面 focus 取消 */
const lostFocusCardCVC = function () {
  this.$emit('creditCardFocus', '');
};

/** 當輸入 CVC ，長度最長只能 3 碼 */
/** 且會將 [toggleCvc] 由 true 轉 false，由 false 轉 true */
/** 觸發 CreditcartDemo 顯示 CVC */
const onInput = (e) => {
  e.target.value = e.target.value.replace(/[^\d]/g, '');
  toggleState('CardInfo', 'toggleCvc');
};

export default {
  name: 'creditcard-cvc',
  inject: ['$validator'],
  components: {
    CreditcardModal,
  },
  methods: {
    focusCardCVC,
    lostFocusCardCVC,
    onInput,
    openModal,
  },
  computed: {
    isCvcRequired() {
      return renderGetter('AmountDetail', 'realInstantAmount') > 0;
    },
    cvcValue: createComputed('CardInfo', 'cvc'),
    cardNo: createComputed('CardInfo', 'cardNo'),
    cardType() {
      return getCardType(this.cardNo);
    },
    isAmex() {
      return this.cardType === 'Amex';
    },
    /** cvc, cvv 長度 */
    cvcMaxLength() {
      return this.isAmex ? 4 : 3;
    },
    isAmexClass() {
      return this.isAmex ? 'isAmex' : '';
    },
  },
  mounted() {
    // cvc clean
    commitMutation('CardInfo', 'cvc', '');
    this.$validator.validate();
  },
};
</script>

<style scoped>
.css-th.align.right.w-f9.isAmex {
  vertical-align: top;
}
</style>
