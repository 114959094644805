<template>
  <div class="panel panel-default cart-form">
    <div class="panel-head">門市取貨資訊</div>
    <div class="panel-body">
      <div class="css-table pure middle">
        <!--收件人-->
        <store-name
          :module="'RecipientInfo'"
          :states="storeNameStates"
          :prefix="'收件人'"
          :postfix="'(中文全名)'"
          :err-msg="'recipientName'"></store-name>
        <!--手機-->
        <store-mobilephone
          :module="'RecipientInfo'"
          :states="storeMobilephoneStates"
          :name="'storeMobilephone'"></store-mobilephone>
        <!--市話-->
        <store-localphone
          :module="'RecipientInfo'"
          :states="storeLocalphoneStates"
          :phoneAreaName="'storeLocalPhoneArea'"
          :phoneName="'storeLocalPhone'"
          :phoneExtName="'storeLocalPhoneExt'"></store-localphone>
        <!--取貨門市-->
        <store-address></store-address>
      </div>
    </div>
  </div>
</template>

<script>
import StoreAddress from '@/components/desktop/step1/delivery-method/store-info/child/store-address.vue';
import StoreLocalphone from '@/components/desktop/step1/common/common-localphone.vue';
import StoreMobilephone from '@/components/desktop/step1/common/common-mobilephone.vue';
import StoreName from '@/components/desktop/step1/common/common-name.vue';

export default {
  name: 'store-info',
  inject: ['$validator'],
  components: {
    StoreName,
    StoreMobilephone,
    StoreLocalphone,
    StoreAddress,
  },
  data: () => ({
    /** module 名稱 */
    module: 'RecipientInfo',
    /** <store-name/> 所使用的 states */
    storeNameStates: ['name'],
    /** <store-mobilephone/> 所使用的 states */
    storeMobilephoneStates: ['mobilePhone'],
    /** <store-localphone/> 所使用的 states */
    storeLocalphoneStates: ['localPhoneArea', 'localPhone', 'localPhoneExt'],
  }),
};
</script>
