<template>
  <!-- 任一折扣即 show isShowDiscountPanel -->
  <div v-if="isShowDiscountItem">
    <div class="panel panel-default cart-form" v-for="(item, index) in getDiscountList" :key="index">
      <div class="panel-body cart-box-discount">
        <input
          class="checkbox"
          :id="`check${item.discountName}`"
          type="checkbox"
          :name="`${item.discountName}`"
          :checked="item.isUsed"
          @change.prevent="updateDiscountListFn(item)" />
        <label class="checkbox-label" :for="`check${item.discountName}`">
          <span class="title-discount spacing middle">{{ item.title }}</span>
          <span class="price">{{ item.total | dollar }}</span>
        </label>
        <!-- 各折扣使用說明 -->
        <p v-if="item.note" class="sub">
          {{ item.note }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { dispatchAction } from '@/helpers/vuex';
import { isShowDiscountItem } from '@/helpers/payer-info';
import { dollar } from '@/helpers/format/dollar';

const computed = {
  isShowDiscountItem,
};

export default {
  name: 'discount-block',
  filters: {
    dollar,
  },
  props: {
    getDiscountList: {
      type: Array,
      require: true,
    },
  },
  computed,
  methods: {
    updateDiscountListFn(item) {
      return dispatchAction('AmountDetail', 'calculateDiscountListFn', item);
    },
  },
};
</script>
