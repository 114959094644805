<template>
  <div class="credit-card-form card-edit">
    <div id="cardEdit">
      <div class="css-table pure middle">
        <!--信用卡號-->
        <creditcard-no @creditCardFocus="changeFocus"></creditcard-no>
        <!--有效期限-->
        <creditcard-expired @creditCardFocus="changeFocus"></creditcard-expired>
        <!--卡片背面末三碼-->
        <creditcard-cvc @creditCardFocus="changeFocus"></creditcard-cvc>
      </div>
    </div>
    <!--信用卡示範-->
    <creditcard-demo :focusType="focusType"></creditcard-demo>
  </div>
</template>

<script>
import CreditcardCvc from '@/components/desktop/step1/payment-method/common/child/child/creditcard-cvc.vue';
import CreditcardDemo from '@/components/desktop/step1/payment-method/common/child/child/creditcard-demo.vue';
import CreditcardExpired from '@/components/desktop/step1/payment-method/common/child/child/creditcard-expired.vue';
import CreditcardNo from '@/components/desktop/step1/payment-method/common/child/child/creditcard-no.vue';

/** 改變 focus 狀態 (NO, EXPIRED, CVC 或空字串) */
const changeFocus = function (input) {
  this.focusType = input;
};

export default {
  name: 'creditcard-input',
  inject: ['$validator'],
  components: {
    CreditcardNo,
    CreditcardExpired,
    CreditcardCvc,
    CreditcardDemo,
  },
  data: () => ({
    /** 信用卡卡面 focus 位置狀態 (NO, EXPIRED, CVC 或空字串) */
    focusType: '',
  }),
  methods: {
    changeFocus,
  },
};
</script>
