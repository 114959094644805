<template>
  <div>
    <div class="panel panel-default cart-form">
      <payment-marquee></payment-marquee>
      <div class="panel-body">
        <payment-type :payments="payments"></payment-type>
        <component :is="renderMatchedComponent()" :is-rtype="isRtype"></component>
      </div>
    </div>
  </div>
</template>

<script>
import {
  commitMutation,
  createDynamicComponent,
  dispatchAction,
  getAction,
  getState,
  renderState,
} from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/payment-method/constant/payment-method.constant';
import CreditcardBonus from '@/components/desktop/step1/payment-method/creditcard-bonus/creditcard-bonus.vue';
import CreditcardInstallment from '@/components/desktop/step1/payment-method/creditcard-installment/creditcard-installment.vue';
import CreditcardOnetime from '@/components/desktop/step1/payment-method/creditcard-onetime/creditcard-onetime.vue';
import ZingalaPayment from '@/components/desktop/step1/payment-method/zingala-payment/zingala-payment.vue';
import PaymentMarquee from '@/components/desktop/step1/payment-method/payment-marquee/payment-marquee.vue';
import PaymentType from '@/components/desktop/step1/payment-method/common/payment-type.vue';

/** 判斷所選取的付款方式長出額外的區塊
 * 預設付款方式為[信用卡一次付款]，如該賣場僅能門市繳費，預設付款方式為[門市繳費]
 * 僅選取[信用卡一次付款][信用卡紅利折抵][信用卡分期付款][零卡分期]會產生出填寫信用卡卡號 OR 零卡分期區塊
 * @return {String}
 * */

const renderMatchedComponent = () => {
  const defaultPaymentMethod = renderState('DynamicComponents', 'paymentMethod');
  const hasExtraBlockPayments = ['creditcard-onetime', 'creditcard-installment', 'creditcard-bonus', 'zingala-payment'];
  const isPaymentsExtraBlock = hasExtraBlockPayments.includes(defaultPaymentMethod);
  return isPaymentsExtraBlock ? defaultPaymentMethod : '';
};

/** 所選擇的付款方式 (radio-button 預設選取)
 * @return: {Number} */
const payment = createDynamicComponent('PaymentInfo', 'payment', 'paymentMethod', componentLut);

/** 信用卡卡別
 * @return: {String} */
const cardType = getState('CardInfo', 'cardType');

/** 可用付款方式 (API 只會傳回可用付款)
 * @return: {Array} */
const payments = () => renderState('PaymentInfo', 'payments');

/** 是否為 R 身份 */
const isRtype = getState('PaymentInfo', 'isPayerReadOnly');

export default {
  name: 'payment-method',
  inject: ['$validator'],
  components: {
    CreditcardOnetime,
    CreditcardBonus,
    CreditcardInstallment,
    ZingalaPayment,
    PaymentMarquee,
    PaymentType,
  },
  computed: {
    cardType,
    payments,
    payment,
    isRtype,
  },
  methods: {
    renderMatchedComponent,
  },
  watch: {
    // 卡號為美國運通卡時更新 payments
    cardType: getAction('PaymentInfo', 'AmexUpdatePaymentType'),
  },
  mounted() {
    // 呼叫快速刷卡 API OR 清除呼叫 /getInfo 殘存的 code 碼
    this.isRtype ? dispatchAction('CardInfo', 'getQuickSettle') : commitMutation('CardInfo', 'code', '');
  },
};
</script>

<style scoped>
.cart-form .has-pay-select label {
  cursor: pointer;
}
/** 付款方式： "ATM轉帳" 字數太短不夠撐高內容 */
/*.cart-form .has-pay-select li {*/
/*  padding: 20px;*/
/*  min-height: 88px;*/
/*}*/
@media screen and (min-width: 1200px) {
  .cart-form .has-pay-select li {
    min-height: 68px;
  }
}
</style>
