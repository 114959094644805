import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import zhTW from 'vee-validate/dist/locale/zh_TW';
import { required, length, isNot, decimal } from '@/validators/config/dictionary';
import {
  recipientName,
  payerName,
  mobilePhone,
  cellPhone,
  city,
  town,
  address,
  store,
  phone,
  cardNo,
  cardMonth,
  cardYear,
  cardCode,
} from '@/validators/config/attribute';
import config from '@/validators/config/config';
import addressLengthRule from '@/validators/rules/address-length';
import cardNoLengthRule from '@/validators/rules/cardno-length';
import chineseOnlyRule from '@/validators/rules/chinese-only';
import creditCard from '@/validators/rules/credit-card';
import customEmail from '@/validators/rules/custom-email';
import cVCLengthRule from '@/validators/rules/cvc-length';
import invoiceIdDevice from '@/validators/rules/invoice-id-device';
import invoiceMobileDevice from '@/validators/rules/invoice-mobile-device';
import localPhone from '@/validators/rules/local-phone';
import localPhoneArea from '@/validators/rules/local-phone-area';
import localPhoneExt from '@/validators/rules/local-phone-ext';
import mobilePhoneRule from '@/validators/rules/mobile-phone';
import postalBoxRule from '@/validators/rules/postal-box';
import selectRequiredRule from '@/validators/rules/select-required';
import taxIDNumberRule from '@/validators/rules/tax-id-number';

/** 自訂欄位、訊息字典檔 */
const dictionary = {
  zh_TW: {
    messages: {
      is_not: isNot,
      length,
      required,
      decimal,
    },
    attributes: {
      recipientName,
      name: recipientName,
      payerName,
      mobilePhone,
      payerMobilephone: mobilePhone,
      storeMobilephone: mobilePhone,
      recipientMobilePhone: mobilePhone,
      cellPhone,
      cardCode,
      cvc: cardCode,
      'card-code': cardCode,
      'card-month': cardMonth,
      'card-no': cardNo,
      cardNo,
      'card-year': cardYear,
      expiredMonth: cardMonth,
      expiredYear: cardYear,
      installmentType: '分期付款方式',
      agreeCheck: 'agreeCheck',
      id_device_code: '自然人憑證條碼載具',
      invoiceAddress: '發票寄送地址',
      invoiceCity: '發票寄送縣市',
      invoiceTown: '發票寄送區域',
      mobile_device_code: '手機條碼載具',
      order_invoice_name: '發票收件人',
      order_invoice_tax_id: '公司統編',
      order_invoice_title: '公司抬頭',
      payer_phone_code: '區碼',
      payer_phone_ext: '分機',
      payer_phone_no: '電話號碼',
      payerAddress: address,
      payerCity: city,
      payerEmail: 'Email',
      email: 'Email',
      payerPhone: phone,
      payerTown: town,
      store,
      city,
      town,
      address,
      order_invoice_donate_id: '發票捐贈方式',
      addressCity: city,
      addressTown: town,
      recipientCityName: city,
      recipientTownName: town,
      recipientAddressName: address,
      invoiceCityName: city,
      invoiceTownName: town,
      invoiceAddressName: address,
      payerCityName: city,
      payerTownName: town,
      payerAddressName: address,
    },
  },
};

/** 設定語系 */
const setLocale = () => Validator.localize('zh_TW', zhTW);

/** 設定字典 */
const setDictionary = () => Validator.localize(dictionary);

/** 設定自訂驗證規則 */
const setCustomRules = () => {
  Validator.extend('credit-card', creditCard);
  Validator.extend('custom-email', customEmail);
  Validator.extend('invoice-id-device', invoiceIdDevice);
  Validator.extend('invoice-mobile-device', invoiceMobileDevice);
  Validator.extend('local-phone', localPhone);
  Validator.extend('local-phone-area', localPhoneArea);
  Validator.extend('local-phone-ext', localPhoneExt);
  Validator.extend('mobile-phone', mobilePhoneRule);
  Validator.extend('postal-box', postalBoxRule);
  Validator.extend('select-required', selectRequiredRule);
  Validator.extend('address-length', addressLengthRule);
  Validator.extend('chinese-only', chineseOnlyRule);
  Validator.extend('cvc-length', cVCLengthRule);
  Validator.extend('cardno-length', cardNoLengthRule);
  Validator.extend('tax-id-number', taxIDNumberRule);
};

export default () => {
  Vue.use(VeeValidate, config);
  setLocale();
  setDictionary();
  setCustomRules();
};
