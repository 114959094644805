<template>
  <div class="css-tr">
    <div class="css-th align right w-f8">
      <b class="notice">*</b>
      有效期限
    </div>
    <div class="css-td">
      <select
        name="expiredMonth"
        v-model="month"
        v-validate="'required'"
        @focus="focusCardExpired"
        @blur="unfocusCardExpired">
        <option value="">請選擇</option>
        <option v-for="item in months" :value="item" :key="item">
          {{ item }}
        </option>
      </select>
      <span class="middle spacing">月</span>
      <select
        name="expiredYear"
        v-model="year"
        v-validate="'required'"
        @focus="focusCardExpired"
        @blur="unfocusCardExpired">
        <option value="">請選擇</option>
        <option v-for="item in years" :value="item" :key="item">
          {{ item }}
        </option>
      </select>
      <span class="middle spacing">年</span>
      <p v-if="errors.has('expiredMonth') || errors.has('expiredYear')" class="error">請選擇有效期限</p>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import { genMonths, genYears } from '@/helpers/date';

/** 切換信用卡卡面 focus 位置 (有效期限) */
const focusCardExpired = function () {
  this.$emit('creditCardFocus', 'EXPIRED');
};

/** 取消信用卡卡面 focus */
const unfocusCardExpired = function () {
  this.$emit('creditCardFocus', '');
};

export default {
  name: 'creditcard-expired',
  inject: ['$validator'],
  data: () => ({
    months: genMonths,
    years: genYears,
  }),
  computed: {
    /** 有效期限 (月) */
    month: createComputed('CardInfo', 'cardExpiredMonth'),
    /** 有效期限 (年) */
    year: createComputed('CardInfo', 'cardExpiredYear'),
  },
  methods: {
    focusCardExpired,
    unfocusCardExpired,
  },
};
</script>
