<template>
  <div>
    <h5>街口支付說明</h5>
    <ul class="has-type-decimal">
      <li>使用街口支付恕不適用分期付款。</li>
      <li>消費可使用街口幣折抵，折抵上限為結帳總金額 30% 。</li>
      <li>街口支付接受街口聯名卡及帳戶付款。</li>
      <li>付款額度限制：每筆 5 萬/每日 10 萬/每月 20 萬。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Jkos',
};
</script>
