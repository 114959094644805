<template>
  <span>
    <p class="notice fn-sm">
      * 依統一發票使用辦法規定，個人戶無法換開為公司戶發票，公司戶發票也無法換為個人戶發票 (
      <a :href="`${baseUrl}/Member/eInvoiceChart`" target="_blank" class="underline"> 財政部電子發票流程說明 </a>
      )
    </p>
    <p class="fn-sm">* 若為預購商品，發票將於商品出貨後再行開立</p>
  </span>
</template>

<script>
export default {
  name: 'invoice-desc',
  data: () => ({
    baseUrl: process.env.VUE_APP_ONLINE,
  }),
};
</script>
