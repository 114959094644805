<template>
  <modal componentName="BankModal" :isModalStatic="true" :isHideFooter="true">
    <template v-slot:title>
      <h4 class="text-left">
        接受
        <b class="notice spacing sm">{{ banks.length }}</b>
        家銀行，{{ period }} 期 {{ rate }} 利率
        <a
          class="underline inverted fn-sm"
          href="https://event.senao.com.tw/20170103_bank/?date=170207&amp;loc=IG1&amp;id=4088"
          target="_blank">
          <i class="icon icon-info-circle"></i>
          詳細說明
        </a>
      </h4>
    </template>
    <template v-slot:body>
      <div class="row striped">
        <span v-for="(item, index) in banks" class="col-md-6" :key="index">
          {{ item.bankName }}
        </span>
      </div>
      <ul class="has-type-disc">
        <li>除不盡餘數於第一期收取</li>
        <li>
          所有價格一律含稅，接受信用卡
          <i class="icon icon-visa"></i>
          <i class="icon icon-master-card"></i>
          <i class="icon icon-jcb"></i>
        </li>
        <li>實際分期金額請依各發卡銀行規定為準</li>
      </ul>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/common-modal.vue';

export default {
  name: 'bank-modal',
  props: [
    /** 每個分期所支援的銀行 */
    'banks',
    /** 每個分期數 */
    'period',
    /** 每個分期利率 */
    'rate',
  ],
  components: {
    Modal,
  },
};
</script>
