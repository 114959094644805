<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      {{ prefix }}
    </div>
    <div class="css-td">
      <input
        v-if="isNotMasked"
        v-model="name"
        v-validate="validateRule"
        :name="errMsg"
        :disabled="isRtype"
        class="input-lg"
        maxlength="7"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedName" class="input-lg" type="text" @focus="isNotMasked = true" />
      <span v-if="isShow" class="sub spacing">
        {{ postfix }}
      </span>
      <!-- ex: 請填信用卡持卡人姓名 -->
      <slot></slot>
      <span class="sub spacing left md">
        <a
          v-if="!isRtype"
          href="javascript:void(0);"
          class="underline spacing"
          tabindex="-1"
          @blur="handleBlur"
          @click="copyBuyerInfo(module)">
          同訂購人資訊
        </a>
      </span>
      <p class="error">{{ errors.first(errMsg) }}</p>
    </div>
  </div>
</template>

<script>
import { compose, not, prop } from 'ramda';
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';
import { index } from '@/components/desktop/step1/common/constant/common-name.constant';
import { isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';

/** [收件人] */
const name = compose(createAdvComputed, prop('name'))(index);

/** 0 元訂單不顯示 [請填信用卡持卡人姓名] */
const isShow = compose(not, isZeroOrder);

export default {
  name: 'common-name',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return [];
      },
    },
    /** 前面所顯示的字串 */
    prefix: {
      type: String,
      default: '',
    },
    /** 後面所顯示的字串 */
    postfix: {
      type: String,
      default: '',
    },
    /** 錯誤訊息 */
    errMsg: {
      type: String,
      default: '',
    },
    validateRule: {
      type: String,
      default: 'required',
    },
    isRtype: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name,
    isShow,
  },
  created() {
    this.setIsNotMaskedByValue(this.name);
  },
  setup(props) {
    const { maskedName, isNotMasked, handleBlur, copyBuyerInfo, setIsNotMaskedByValue } = useMask(props);
    return { maskedName, isNotMasked, handleBlur, copyBuyerInfo, setIsNotMaskedByValue };
  },
};
</script>
