<template>
  <modal
    :isModalStatic="true"
    :isHideHeaderCloseButton="true"
    :isHideFooter="true"
    modalSizeCssClass="lg"
    componentName="RegisterModal">
    <template v-slot:body>
      <component
        :is="step"
        :mobile-phone="mobilePhone"
        @get-member-registration="$emit('get-member-registration')"
        @cancel="closeModal('RegisterModal')"
        @get-sms-send="$emit('get-member-registration')"></component>
    </template>
  </modal>
</template>

<script>
import { getState } from '@/helpers/vuex';
import { closeModal } from '@/helpers/modal';
import Modal from '@/components/common/common-modal.vue';
import Step1 from '@/components/desktop/step1/register-info-modal/step1.vue';
import Step2 from '@/components/desktop/step1/register-info-modal/step2.vue';

export default {
  name: 'register-info-modal',
  inject: ['$validator'],
  props: {
    mobilePhone: {
      type: String,
      default: '',
    },
  },
  components: {
    Modal,
    Step1,
    Step2,
  },
  computed: {
    step: getState('RegisterSenaoMembership', 'step'),
  },
  methods: {
    closeModal,
  },
};
</script>
