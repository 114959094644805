<template>
  <!-- 信用卡分期 -->
  <div class="total billboard">
    <div class="total-item">
      <span class="label" v-if="selectInstallmentIndex"> 本次分期實付金額 </span>
      <span class="label" v-else> 本次實付金額 </span>
      <span class="price">{{ instantAmount | dollar }}</span>
      <span class="sub-price" v-if="selectInstallmentIndex"> (每期{{ moneyPerMonth | dollar }}) </span>
    </div>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { getGetter, renderState } from '@/helpers/vuex';

export default {
  name: 'total-amount-with-installment',
  filters: {
    dollar,
  },
  computed: {
    /** 本次實付金額 */
    instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
    /** 是否顯示分期每期金額 (可顯示的條件如下:)
     *  1.未選取分期付款時不顯示
     *  2.instantAmount 非為 0 元時 (ex: 切換使用折扣金)
     */
    selectInstallmentIndex() {
      if (renderState('InstallmentInfo', 'installmentIndex') !== null) {
        return this.instantAmount !== 0;
      }
      return false;
    },
    /** 分期金額 */
    moneyPerMonth: getGetter('InstallmentInfo', 'moneyPerMonth'),
  },
};
</script>
