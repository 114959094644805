<template>
  <div class="credit-card-form card-record" v-if="!isRtype">
    <div id="cardRecord">
      <div class="css-table pure middle">
        <div class="css-tr">
          <div class="css-th align right w-f8">
            <b class="notice">*</b>
            信用卡號
          </div>
          <div class="css-td">
            <span class="number">{{ quickCardNo }}</span>
            <a class="underline inverted fn-sm" @click="onChangeCard"> 更換卡片 </a>
          </div>
        </div>
        <div class="css-tr">
          <div class="css-th align right w-f8">
            <b class="notice">*</b>
            有效期限
          </div>
          <div class="css-td">
            <span class="number">** 月 **** 年</span>
          </div>
        </div>
        <creditcard-cvc></creditcard-cvc>
      </div>
    </div>
  </div>
</template>

<script>
import { compose } from 'ramda';
import { getState, commitMutation, renderState } from '@/helpers/vuex';
import { substr } from '@/helpers/string';
import { getCardType } from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';
import CreditcardCvc from '@/components/desktop/step1/payment-method/common/child/child/creditcard-cvc.vue';

/** 產生 [快速刷卡] 所需要的卡號 */
const makeQuickCardNo = (start, end = 4) => compose(substr(start, end), getState('CardInfo', 'quickCardNo'));

/** 按下 [更換卡片] */
const onChangeCard = () => {
  commitMutation('PaymentInfo', 'isShowRecord', false);
  // 信用卡卡別清空
  commitMutation('CardInfo', 'cardType', '');
};

/** 是否為 R 身份 */
const isRtype = getState('PaymentInfo', 'isPayerReadOnly');

export default {
  name: 'creditcard-record',
  inject: ['$validator'],
  components: {
    CreditcardCvc,
  },
  methods: {
    onChangeCard,
  },
  computed: {
    // 快速刷卡格式 (區分一般卡或美國運通卡)
    quickCardNo() {
      const type = getCardType(renderState('CardInfo', 'quickCardNo')) || '';
      return type === 'Amex'
        ? `${makeQuickCardNo(0)()} - ****** - *${makeQuickCardNo(11)()}`
        : `${makeQuickCardNo(0)()} - ${makeQuickCardNo(4)()} - **** - ${makeQuickCardNo(12)()}`;
    },
    isRtype,
  },
};
</script>
