<template>
  <div class="css-tr">
    <div class="css-th align right w-f5">
      <b class="notice">*</b>
      Email
    </div>
    <div class="css-td">
      <input
        v-if="isNotMasked"
        v-model="email"
        v-validate="'required|custom-email'"
        :name="emailName"
        :disabled="isRtype"
        class="input-lg"
        maxlength="40"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedEmail" class="input-lg" type="text" @focus="isNotMasked = true" />
      <p class="error">{{ errors.first(emailName) }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';
import { index } from '@/components/desktop/step1/payer-info/child/constant/payer-email.constant';

export default {
  name: 'payer-email',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return ['email'];
      },
    },
    emailName: {
      type: String,
      default: 'email',
    },
    isRtype: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** [Email] */
    email: createAdvComputed(index.emailIndex),
  },
  created() {
    this.setIsNotMaskedByValue(this.email);
  },
  setup(props) {
    const { maskedEmail, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedEmail, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
