<template>
  <div class="total-item row-last">
    <span class="label">訂單金額：</span>
    <span class="price">{{ totalAmount | dollar }}</span>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { getGetter } from '@/helpers/vuex';

export default {
  name: 'total-amount',
  filters: {
    dollar,
  },
  computed: {
    /** 應付總金額 */
    totalAmount: getGetter('AmountDetail', 'realTotalAmount'),
  },
};
</script>
