<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      E-Mail
    </div>
    <div class="css-td">
      <input
        v-if="isNotMasked"
        v-model="email"
        :name="name"
        v-validate="'required|custom-email'"
        class="input-lg"
        maxlength="40"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedEmail" class="input-lg" type="text" @focus="isNotMasked = true" />
      <p class="error">{{ errors.first(name) }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';
import { index } from '@/components/desktop/step1/common/constant/common-email.constant';

export default {
  name: 'recipient-email',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return [];
      },
    },
    /** 錯誤訊息 */
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    /** [Email] */
    email: createAdvComputed(index.emailIndex),
  },
  created() {
    this.setIsNotMaskedByValue(this.email);
  },
  setup(props) {
    const { maskedEmail, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedEmail, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
