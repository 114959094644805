<template>
  <div class="modal fade" id="address-modal">
    <!-- if addressInfo data empty -->
    <div v-if="!hasInfo" class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 style="font-weight: normal">尚未設定收件人資訊</h5>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" data-dismiss="modal">關閉</button>
        </div>
      </div>
    </div>
    <!-- has addressInfo data -->
    <div v-if="hasInfo" class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3>【 請點選收件人 】</h3>
        </div>
        <div class="modal-body">
          <div class="css-table middle stackable">
            <div class="css-tr">
              <div class="css-th">姓名</div>
              <div class="css-th">手機</div>
              <div class="css-th">市話</div>
              <div class="css-th">E-mail</div>
              <div class="css-th">地址</div>
              <div class="css-th align center w-f5">選取</div>
            </div>
            <div v-for="(item, index) in addressees" :key="index" class="css-tr">
              <div class="css-td">{{ maskName(item.name) }}</div>
              <div class="css-td">{{ maskMobile(item.mobile) }}</div>
              <div class="css-td">{{ maskTel(item.phone) }}</div>
              <div class="css-td">{{ maskEmail(item.email) }}</div>
              <div class="css-td">{{ item.cityName }}{{ item.townName }}{{ maskAddress(item.address) }}</div>
              <div class="css-td align center w-f5">
                <button class="btn btn-default btn-sm" @click="onSelect(index, item)">
                  {{ item.caption }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" data-dismiss="modal">取消</button>
          <button class="btn btn-primary" @click="onConfirmSelect" :data-dismiss="dataDismiss">確認選取</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { applySpec, compose, prop, when } from 'ramda';
import { commitMutations, getState, renderState } from '@/helpers/vuex';
import { makeIsWindowClosable, makeOnSelect } from '@/helpers/modal';
import { maskName, maskTel, maskMobile, maskAddress, maskEmail } from '@/helpers/mask';
import { telToObject } from '@/helpers/telephone';

/** 若已經選取 [收件人]，才可以關閉視窗 */
const isWindowClosable = makeIsWindowClosable('AddressModalInfo');

/** 先取得 selectedItem.phone，再轉成 object，最後取其特定 property */
const propPhone = (propName) => compose(prop(propName), telToObject, prop('phone'));

/** 將所選擇的 [收件人] 存 RecipientInfo store */
const saveRecipientInfo = compose(
  commitMutations('RecipientInfo'),
  applySpec({
    name: prop('name'),
    mobilePhone: prop('mobile'),
    localPhoneArea: propPhone('area'),
    localPhone: propPhone('number'),
    localPhoneExt: propPhone('ext'),
    email: prop('email'),
    addressCityId: prop('cityId'),
    addressCity: prop('cityName'),
    addressTownId: prop('townId'),
    addressTown: prop('townName'),
    address: prop('address'),
    addressZip: prop('zipcode'),
  }),
  getState('AddressModalInfo', 'selectedItem')
);

/** 確認選取 */
/** 當有選取任何一個 [收件人]，才可以繼續 */
const onConfirmSelect = when(isWindowClosable, saveRecipientInfo);

export default {
  name: 'address-modal',
  computed: {
    /** 從 API 下載的 [收件人記事本] */
    addressees: getState('AddressModalInfo', 'addressees'),
    /** 關閉 [收件人記事本] */
    dataDismiss: getState('AddressModalInfo', 'dataDismiss'),
    /** 判斷[收件人記事本]是否有資料 */
    hasInfo() {
      return renderState('AddressModalInfo', 'addressees').length > 0;
    },
  },
  methods: {
    onConfirmSelect,
    /** 選取 [收件人] */
    onSelect: makeOnSelect('AddressModalInfo', 'toggleTitle'),
    maskName,
    maskTel,
    maskMobile,
    maskEmail,
    maskAddress,
  },
};
</script>
