<template>
  <div class="alert alert-default spacing top" data-panel="carrier">
    <label v-for="(value, key) in devices" :key="key" class="radio-inline spacing right">
      <input v-model="electronicInvoice" :value="key" type="radio" name="carrier" />
      {{ value }}
    </label>
    <component :is="dynamicComponent"></component>
  </div>
</template>

<script>
import { createDynamicComponent, getState } from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/invoice-method/electronic-invoice/constant/electronic-invoice.constant';
import MobileDevice from '@/components/desktop/step1/invoice-method/electronic-invoice/child/mobile-device.vue';
import NaturalDevice from '@/components/desktop/step1/invoice-method/electronic-invoice/child/natural-device.vue';

export default {
  name: 'electronic-invoice',
  inject: ['$validator'],
  components: {
    MobileDevice,
    NaturalDevice,
  },
  data: () => ({
    /** 所有電子發票載具 */
    devices: {
      '01': '神腦會員載具',
      '02': '手機條碼載具',
      '03': '自然人憑證條碼載具',
    },
  }),
  computed: {
    /** 決定 dynamic component 要使用哪一個 component */
    dynamicComponent: getState('DynamicComponents', 'electronicInvoice'),
    /** 電子發票載具 */
    electronicInvoice: createDynamicComponent('InvoiceInfo', 'invoiceDefault', 'electronicInvoice', componentLut),
  },
};
</script>
