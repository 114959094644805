<template>
  <div>
    <h5>銀角零卡-無卡分期 付款說明</h5>
    <ul class="has-type-decimal">
      <li>銀角零卡-無卡分期 付款服務由仲信資融股份有限公司提供。</li>
      <li class="danger">分期實付金額以與仲信資融申請議定內容為準。</li>
      <li class="danger">前往仲信資融股份有限公司填寫申請分期資料，需於訂單成立20分鐘內逾期將會自動取消訂單。</li>
      <li class="danger">
        訂單需於審核效期內完成審核，逾時將會自動取消訂單。於仲信資融填寫完申請分期資料後，請保持電話暢通，仲信資融將與您聯絡進行資料確認。
      </li>
      <li>若申請審核未核准，將會自動取消訂單。若申請審核成功，神腦生活接獲仲信資融通知後，會盡速安排出貨。</li>
      <li>關於分期審核進度、分期帳款繳款及退款問題，請洽仲信資融客服專線。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Zingala',
};
</script>
