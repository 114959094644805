<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">銷售員工工號</div>
    <div class="css-td">
      <input v-model="id" class="input-lg" maxlength="10" autocomplete="off" type="text" />
    </div>
  </div>
</template>

<script>
import { compose, prop } from 'ramda';
import { createAdvComputed } from '@/helpers/vuex';
import { index } from '@/components/desktop/step1/common/constant/common-name.constant';

const id = compose(createAdvComputed, prop('name'))(index);

export default {
  name: 'recipient-employee-id',
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    id,
  },
};
</script>
