<template>
  <div>
    <h5>悠遊付付款說明</h5>
    <ul class="has-type-decimal">
      <li>使用悠遊付恕不適用分期付款。</li>
      <li>悠遊付限使用信用卡付款</li>
      <li>於訂單成立後 20 分鐘內須完成付款</li>
      <li>付款額度限制：單月 30 萬。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EasyPay',
};
</script>
