<template>
  <div class="css-tr">
    <div class="css-th"></div>
    <div class="css-td">
      <p class="notice fn-sm">* 電子發票-公司(紙本)將於鑑賞期過後寄出</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paper-desc',
};
</script>
