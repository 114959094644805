<template>
  <div class="css-tr">
    <div class="font-bold align right w-f6 spacing top">
      <b class="notice">*</b>
      取貨門市
    </div>
    <div class="css-td pulldownChangeSelectorStore-group">
      <!--鄉鎮門市三連動-->
      <city-town-store></city-town-store>
    </div>
  </div>
</template>

<script>
import CityTownStore from '@/components/desktop/step1/common/common-city-town-store.vue';

export default {
  name: 'store-address',
  inject: ['$validator'],
  components: {
    CityTownStore,
  },
};
</script>
