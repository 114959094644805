<template>
  <div>
    <creditcard-record v-if="isShowRecord"></creditcard-record>
    <creditcard-input v-else></creditcard-input>
  </div>
</template>

<script>
import fetchCardInfo from '@/api/step1/card-info';
import store from '@/store/index';
import { commitMutation, createComputed, dispatchAction } from '@/helpers/vuex';
import { getInfoResponse } from '@/helpers/payer-info';
import { isLoginUser } from '@/helpers/is-from-app';
import CreditcardInput from '@/components/desktop/step1/payment-method/common/child/creditcard-input.vue';
import CreditcardRecord from '@/components/desktop/step1/payment-method/common/child/creditcard-record.vue';

export default {
  name: 'creditcard-info',
  inject: ['$validator'],
  components: {
    CreditcardInput,
    CreditcardRecord,
  },
  computed: {
    /**
     * show 可快速刷卡的條件:
     * /getInfo code = 1
     * isPayerReadOnly = 1
     *  */
    isShowRecord: createComputed('PaymentInfo', 'isShowRecord'),
    isLoginUser,
  },
  mounted() {
    if (!this.isLoginUser) {
      commitMutation('PaymentInfo', 'isShowRecord', false);
      return;
    }
    const isRtype = store.state.PaymentInfo.isPayerReadOnly;
    if (isRtype) {
      commitMutation('PaymentInfo', 'isShowRecord', true);
      dispatchAction('CardInfo', 'getQuickSettle');
      return;
    }
    const fetchCardInfoResCode = store.state.CardInfo.code;
    const notFetchedCardInfo = '';
    if (fetchCardInfoResCode === notFetchedCardInfo) {
      fetchCardInfo().then(getInfoResponse);
    }
  },
};
</script>
