<template>
  <div class="row">
    <div class="alert alert-default alert-sm clearfix" v-if="!isRtype">
      <span class="spacing sm middle">* 限用台灣核發信用卡</span>
      <i class="icon icon-visa"></i>
      <i class="icon icon-master-card"></i>
      <i class="icon icon-jcb"></i>
      <i class="icon icon-amex"></i>
      <span class="spacing sm middle">系統自動帶出商品共同可接受分期數</span>
    </div>
    <!--    如是 R 身份則隱藏此 component-->
    <div class="row credit-card-area">
      <creditcard-info v-if="!isRtype"></creditcard-info>
    </div>
  </div>
</template>

<script>
import CreditcardInfo from '@/components/desktop/step1/payment-method/common/creditcard-info.vue';

export default {
  name: 'creditcard-onetime',
  components: { CreditcardInfo },
  props: {
    isRtype: Boolean,
  },
};
</script>
