<template>
  <div class="container">
    <main class="row">
      <cart-detail></cart-detail>
      <delivery-method v-if="isShowDeliveryMethod"></delivery-method>
      <order-info v-else></order-info>
      <!-- 使用折扣選項勾選區 -->
      <discount-block :get-discount-list="getDiscountList"></discount-block>
      <amount-detail></amount-detail>
      <vtp-desc v-if="isVtp"></vtp-desc>
      <payment-method v-if="isShowPaymentMethod"></payment-method>
      <component :is="payerInfoComponent" :key="payerInfoComponent"></component>
      <invoice-method v-if="isShowInvoiceMethod"></invoice-method>
      <total-amount v-if="!isInstallment" :is-zingala-payment="isZingalaPayment"></total-amount>
      <total-amount-with-installment v-if="isInstallment"></total-amount-with-installment>
      <confirm-payment v-if="showConfirmPayment"></confirm-payment>
      <vtp-modal v-if="isVtp"></vtp-modal>
    </main>
    <invoice-modal></invoice-modal>
  </div>
</template>

<script>
import { always, both, equals, __, ifElse, length, not, nth, compose, prop, propEq, when, either } from 'ramda';
import { getState, getAction, getMutation, renderState, dispatchAction, getGetter } from '@/helpers/vuex';
import { componentLut, deliveryEnum } from '@/components/desktop/step1/constant/step1.constant';
import { paymentEnum } from '@/constant/payment-info';
import { isZeroOrder } from '@/components/desktop/step1/payer-info/common/payer-info';
import AmountDetail from '@/components/desktop/step1/amount-detail/amount-detail.vue';
import CartDetail from '@/components/desktop/step1/cart-detail/cart-detail.vue';
import ConfirmPayment from '@/components/desktop/step1/confirm-payment/confirm-payment.vue';
import CreditcardPayer from '@/components/desktop/step1/payer-info/creditcard-payer.vue';
import DeliveryMethod from '@/components/desktop/step1/delivery-method/delivery-method.vue';
import discountBlock from '@/components/desktop/step1/discount-block/discount-block.vue';
import InvoiceMethod from '@/components/desktop/step1/invoice-method/invoice-method.vue';
import InvoiceModal from '@/components/desktop/step1/invoice-method/paper-invoice/child/invoice-modal.vue';
import PaymentMethod from '@/components/desktop/step1/payment-method/payment-method.vue';
import StorePayer from '@/components/desktop/step1/payer-info/store-payer.vue';
import TotalAmount from '@/components/desktop/step1/total-amount/total-amount.vue';
import TotalAmountWithInstallment from '@/components/desktop/step1/total-amount/total-amount-with-installment.vue';
import VtpDesc from '@/components/desktop/step1/vtp-desc/vtp-desc.vue';
import VtpModal from '@/components/common/modals/vtp-modal.vue';
import OrderInfo from '@/components/desktop/step1/order-info/order-info.vue';

/** [配送方式] 為 [單筆資料] */
const isOnlyOneDeliveryMethod = compose(equals(1), length);

/** [配送方式] 為 [不需配送] */
const isNoDelivery = compose(propEq('delivery', prop('noDelivery', deliveryEnum)), nth(0));

/** 是否顯示 [配送方式] */
/** 若 [配送方式] 為 [單筆資料] 且為 [免配送]，則不顯示 [配送方式] */
const isShowDeliveryMethod = compose(
  not,
  both(isOnlyOneDeliveryMethod, isNoDelivery),
  getState('DeliveryInfo', 'deliveries')
);

/** [0 元訂單] 不顯示 [付款方式] */
const isShowPaymentMethod = compose(not, isZeroOrder);

/** [0 元訂單] 不顯示 [發票開立方式] */
const isShowInvoiceMethod = compose(not, isZeroOrder);

/** 根據 [目前選擇付款方式] 查表 */
const lookupComponent = compose(prop(__, componentLut), getState('PaymentInfo', 'payment'));

/** 決定 [付款人資訊] 為 [信用卡付款人] 或 [其他付款人] */
/** 若為 [0 元訂單]，則使用 [信用卡付款人]，其他根據 [目前選擇付款方式] 查表 */
const payerInfoComponent = ifElse(isZeroOrder, always('creditcard-payer'), lookupComponent);

/** 是否為 [電子票券] */
const isVtp = getState('AmountDetail', 'isVTP');

/** 是否為 [免配送] */
const isNoDeliveryStatus = compose(
  either(equals(deliveryEnum.noDelivery), equals('')),
  getState('DeliveryInfo', 'delivery')
);

/** 將 [所選擇配送方式] 設定為 '0' */
const setDefaultToDelivery = getMutation('DeliveryInfo', 'delivery', '0');

/** 判斷付款方式是否為信用卡分期 */
const isInstallment = function () {
  return renderState('PaymentInfo', 'payment') === paymentEnum.creditCardInstallment;
};

/**
 * 判斷付款方式是否為零卡分期
 * @returns {boolean}
 */
const isZingalaPayment = () => renderState('PaymentInfo', 'payment') === paymentEnum.ZingalaPayment;

/** 控制送出按鈕顯示 */
const showConfirmPayment = function () {
  return renderState('Step1Component', 'dynamicComponent') === 'Checkout';
};

/** 非 R 身份則可打 getInfo API (from city-town-taiwan.js) */
/** 先 [取得縣市鄉鎮] API，再 [取得信用卡持卡人] API */
/** 若為 [免配送]，將 [所選擇配送方式] 設定為預設值 '0' */
const mounted = function () {
  const isPayerReadOnly = renderState('PaymentInfo', 'isPayerReadOnly');
  const notRtype = compose(
    when(isNoDeliveryStatus, setDefaultToDelivery),
    getAction('CityTownTaiwan', 'getAllCityTownTaiwan')
  );
  const rType = compose(
    when(isNoDeliveryStatus, setDefaultToDelivery),
    getAction('CityTownTaiwan', 'getAllCityTownTaiwanNoGetInfo')
  );
  if (!isPayerReadOnly) {
    notRtype();
  } else {
    rType();
  }
  // 先 [取得福利金] API，再 [運費 + 實付金額] API
  dispatchAction('AmountDetail', 'getVCash');
};

export default {
  name: 'step1',
  inject: ['$validator'],
  components: {
    OrderInfo,
    CartDetail,
    DeliveryMethod,
    discountBlock,
    AmountDetail,
    PaymentMethod,
    CreditcardPayer,
    StorePayer,
    InvoiceMethod,
    TotalAmount,
    TotalAmountWithInstallment,
    ConfirmPayment,
    VtpModal,
    VtpDesc,
    InvoiceModal,
  },
  computed: {
    isShowDeliveryMethod,
    isShowPaymentMethod,
    isShowInvoiceMethod,
    isVtp,
    payerInfoComponent,
    isInstallment,
    isZingalaPayment,
    showConfirmPayment,
    getDiscountList: getGetter('AmountDetail', 'getterDiscountList'),
  },
  mounted,
};
</script>
