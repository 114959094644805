<template>
  <div class="panel panel-default cart-form">
    <div class="panel-head">發票開立方式</div>
    <div class="panel-body">
      <!-- 說明 -->
      <invoice-desc></invoice-desc>
      <!--發票開立方式-->
      <div class="clearfix divider horizontal">
        <label v-for="(value, key) in invoices" :key="key" class="radio-inline spacing right">
          <input v-model="invoice" :value="value.type" type="radio" name="invoice" />
          {{ value.title }}
        </label>
      </div>
      <!-- 電子發票-個人、發票捐贈、電子發票-公司(紙本) -->
      <component :is="dynamicComponent"></component>
    </div>
    <!-- 注意事項 -->
    <notice-item></notice-item>
  </div>
</template>

<script>
import { prop } from 'ramda';
import { renderState, commitMutation } from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/invoice-method/constant/invoice-method.constant';
import DonateInvoice from '@/components/desktop/step1/invoice-method/donate-invoice/donate-invoice.vue';
import ElectronicInvoice from '@/components/desktop/step1/invoice-method/electronic-invoice/electronic-invoice.vue';
import InvoiceDesc from '@/components/desktop/step1/invoice-method/invoice-desc/invoice-desc.vue';
import NoticeItem from '@/components/desktop/step1/invoice-method/notice-item/notice-item.vue';
import PaperInvoice from '@/components/desktop/step1/invoice-method/paper-invoice/paper-invoice.vue';

/** 列出所有能開立發票方式，但是只顯示 01電子發票-個人、04發票捐贈、05電子發票-公司(紙本) (show: true) */
const invoices = function () {
  /** 從 API 傳來能開立發票的代號 */
  const allowInvoice = renderState('InvoiceInfo', 'invoiceType');
  /** 篩選出 invoiceAll 有對應到的 obj.type 並印出 */
  const invoicesType = this.invoiceAll.filter((x) => allowInvoice.indexOf(x.type) > -1 && x.show === true);
  return invoicesType;
};

/** show 出對應的開發票方式 */
const dynamicComponent = function () {
  /** 動態切換所選的開立發票方式 */
  try {
    const useInvoice = this.invoiceAll.filter((x) => x.group === this.invoice);
    return useInvoice.length >= 1 ? useInvoice[0].component : '';
  } catch (error) {
    console.log(error);
    return '';
  }
};

export default {
  name: 'invoice-method',
  inject: ['$validator'],
  components: {
    InvoiceDesc,
    NoticeItem,
    ElectronicInvoice,
    DonateInvoice,
    PaperInvoice,
  },
  data: () => ({
    /**
     * 所有發票開立方式
     * group: 依照 Vuex InvoiceInfo->invoiceDefault 的值來判斷第二層該使用哪個 component
     * show: 為 true 時顯示於第一層列表
     */
    invoiceAll: [
      { type: '01', group: '01', title: '電子發票-個人', component: 'electronic-invoice', show: false },
      { type: '02', group: '01', title: '手機條碼', component: 'electronic-invoice', show: false },
      { type: '03', group: '01', title: '自然人憑證條碼載具', component: 'electronic-invoice', show: false },
      { type: '04', group: '04', title: '發票捐贈', component: 'donate-invoice', show: false },
      { type: '05', group: '05', title: '電子發票-公司(紙本)', component: 'paper-invoice', show: false },
    ],
  }),
  computed: {
    /** 決定 dynamic component 要使用哪一個 component */
    dynamicComponent,
    /** 預設發票開立方式 */
    invoice: {
      get() {
        const storeInvoice = renderState('InvoiceInfo', 'invoiceDefault');
        const getInvoice = this.invoiceAll.find((x) => x.type === storeInvoice);
        return getInvoice !== undefined ? getInvoice.group : '';
      },
      set(value) {
        commitMutation('DynamicComponents', 'electronicInvoice', value === '01' ? '' : false);
        commitMutation('DynamicComponents', 'invoiceMethod', prop(value, componentLut));
        commitMutation('InvoiceInfo', 'invoiceDefault', value);
      },
    },
    /** 所有開立方票方式 */
    invoices,
  },
  created() {
    /** 依照 api 及發票開立方式 componentLut 來內容決定哪些顯示於第一層 */
    this.invoiceAll.forEach((elem) => (elem.show = componentLut[elem.type] !== undefined));
  },
};
</script>
