<template>
  <div>
    <!--市-->
    <city-town-select
      v-model="cityIndex"
      :source="cities"
      v-validate="isNotPleaseSelect"
      name="city"></city-town-select>
    &nbsp;
    <!--鄉鎮區-->
    <city-town-select v-model="townIndex" :source="towns" v-validate="isNotPleaseSelect" name="town"></city-town-select>
    &nbsp;
    <!--神腦門市-->
    <city-town-select
      v-model="storeIndex"
      :source="stores"
      v-validate="isNotPleaseSelect"
      name="store"></city-town-select>
    <p class="error" v-if="errors.has('city')">
      {{ errors.first('city') }}
    </p>
    <p class="error" v-if="errors.has('town')">
      {{ errors.first('town') }}
    </p>
    <p class="error" v-if="errors.has('store')">
      {{ errors.first('store') }}
    </p>
    <!--顯示地址-->
    <div v-if="isShowAddress" class="addr">
      <span class="storeName">{{ store }}-{{ typeName }} ({{ fullAddress }})</span>
      <span class="spacing">
        <i class="icon icon-google-map"></i>
        <a
          class="underline storeAddress"
          :href="`http://maps.google.com.tw/maps?f=q&amp;hl=zh-TW&amp;geocode=&amp;z=14&amp;q=${fullAddress}`"
          target="_blank">
          顯示地圖
        </a>
      </span>
      <br />
      <div class="storeHours">
        <span class="font-bold">營業時間</span>
        <span class="text sh">
          <dl class="accordion acc-time-2">
            <dt class="accordion-title">
              <a href="javascript:void(0);" @click="isShowAccordionContent = !isShowAccordionContent">
                <span :class="['spacing left mini st_green', storeOpenOrClosedStyle]">{{ storeOpenOrClosedText }}</span>
                ⋅ {{ weekList[calculateWeekListMatchingDay].day }} {{ todayTime }}
                <i :class="['icon', isShowAccordionContent ? 'icon-arrow-up' : 'icon-arrow-down']"></i>
              </a>
            </dt>
            <dd class="accordion-content" v-if="isShowAccordionContent">
              <div class="iframe-wrap">
                <ul>
                  <li v-for="(item, index) in weekList" :key="index">
                    <p :class="{ 'font-bold': index === calculateWeekListMatchingDay }">{{ item.day }}</p>
                    <p :class="{ 'font-bold': index === calculateWeekListMatchingDay }">{{ item.time }}</p>
                  </li>
                </ul>
              </div>
            </dd>
          </dl>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { calculateWeekListMatchingDay } from '@/helpers/date';
import { commitMutation, getGetter, dispatchAction, getState, renderState } from '@/helpers/vuex';
import CityTownSelect from '@/components/desktop/step1/common/city-town/city-town-select.vue';

export default {
  name: 'city-town-store',
  inject: ['$validator'],
  components: {
    CityTownSelect,
  },
  data() {
    return {
      isShowAccordionContent: false,
    };
  },
  computed: {
    /** [動態城市] index */
    cityIndex: {
      get: getState('CityTownStores', 'cityIndex'),
      set(value) {
        // console.log(`cityIndex: ${value}`);
        this.townIndex = 0;
        commitMutation('CityTownStores', 'cityIndex', value);
        commitMutation('RecipientInfo', 'senaoCity', this.cities[value].name);
        commitMutation('RecipientInfo', 'senaoTown', '');
      },
    },
    /** [動態鄉鎮] index */
    townIndex: {
      get: getState('CityTownStores', 'townIndex'),
      set(value) {
        // console.log(`  townIndex: ${value}`);
        this.storeIndex = 0;
        commitMutation('CityTownStores', 'townIndex', value);
        commitMutation('RecipientInfo', 'senaoTown', this.towns[value].name);
      },
    },
    /** [動態門市] index */
    storeIndex: {
      get: getState('CityTownStores', 'storeIndex'),
      set(value) {
        // console.log(`    storeIndex: ${value}`);
        commitMutation('CityTownStores', 'storeIndex', value);
        commitMutation('RecipientInfo', 'senaoStore', this.stores[value].name);
        commitMutation('RecipientInfo', 'senaoStoreId', this.stores[value].storeId || '');
      },
    },
    /** 顯示第一層 select 所需的 array */
    cities: getGetter('CityTownStores', 'cities'),
    /** 顯示第二層 select 所需的 array */
    towns: getGetter('CityTownStores', 'towns'),
    /** 顯示第三層 select 所需的 array */
    stores: getGetter('CityTownStores', 'stores'),
    /** 顯示門市名稱 */
    store: getGetter('CityTownStores', 'store'),
    /** 顯示地址的 '市' */
    county: getGetter('CityTownStores', 'county'),
    /** 顯示地址 */
    address: getGetter('CityTownStores', 'address'),
    /** 顯示全地址 */
    fullAddress: getGetter('CityTownStores', 'fullAddress'),
    /** typeName */
    typeName: getGetter('CityTownStores', 'typeName'),
    /** 是否顯示 '地址' 區塊 */
    isShowAddress: getGetter('CityTownStores', 'isShowAddress'),
    /** 鄉鎮市不能是 `請選擇` */
    isNotPleaseSelect: () => ({ is_not: 0 }),
    todayTime() {
      return this.weekList[this.calculateWeekListMatchingDay].time;
    },
    weekList: getGetter('CityTownStores', 'weekList'),
    calculateWeekListMatchingDay,
    storeOpenOrClosedText() {
      return this.todayTime === '休息' ? '休息中' : '營業中';
    },
    storeOpenOrClosedStyle() {
      return this.todayTime === '休息' ? 'st_red' : 'st_green';
    },
  },
  /** 取得特約中心門市資訊 API */
  mounted() {
    const senaoStoreId = renderState('RecipientInfo', 'senaoStoreId');
    new Promise((resolve) => resolve())
      .then(() => dispatchAction('CityTownStores', 'getAllCityTownStores'))
      .then(() => (senaoStoreId ? false : dispatchAction('CityTownStores', 'getGetStore')));
    this.$validator.validate();
  },
};
</script>
<style scoped>
.icon-arrow-up {
  background-image: url('/images/icons/icon-arrow-up.svg');
}
</style>
