<template>
  <div class="css-tr">
    <div class="css-th align right w-f8">
      <b class="notice">*</b>
      信用卡號
    </div>
    <div class="css-td">
      <span class="input-card-number">
        <input
          v-model="cardNo"
          ref="cardNo"
          v-validate="'required|cardno-length'"
          @focus="focusCardNO"
          @blur="unfocusCardNO"
          @keypress="isNumber($event)"
          maxlength="16"
          type="text"
          name="cardNo"
          class="input"
          autocomplete="off" />
      </span>
      <a class="underline inverted fn-sm" @click="onClearAll"> 清除重填 </a>
      <p v-if="errors.has('cardNo')" class="error">
        {{ errors.first('cardNo') }}
      </p>
    </div>
  </div>
</template>

<script>
import { commitMutation, commitMutations, createComputed } from '@/helpers/vuex';
import { isNumber } from '@/helpers/number';
import { getCardType } from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';

/** 清除重填 */
const onClearAll = () => {
  commitMutations('CardInfo', {
    cardNo: '',
    cardExpiredMonth: '',
    cardExpiredYear: '',
  });

  document.getElementById('cvc').value = '';
};

/** 信用卡卡面 focus 卡號 */
const focusCardNO = function () {
  this.$emit('creditCardFocus', 'NO');
};

/** 信用卡卡面 focus 取消 */
const unfocusCardNO = function () {
  this.$emit('creditCardFocus', '');
};

export default {
  name: 'creditcard-no',
  inject: ['$validator'],
  computed: {
    cardNo: createComputed('CardInfo', 'cardNo'),
    cardType() {
      return getCardType(this.cardNo);
    },
  },
  methods: {
    onClearAll,
    focusCardNO,
    unfocusCardNO,
    isNumber,
  },
  watch: {
    // 更新信用卡卡別
    cardType() {
      commitMutation('CardInfo', 'cardType', getCardType(this.cardNo));
    },
  },
};
</script>
