<template>
  <div class="panel panel-default total horizontal-single">
    <div class="total-item-box" v-if="isShowDiscountItem">
      <span class="row-last-th">各折抵項目 依照頁面顯示 上→下 依序進行折抵</span>
    </div>
    <!--小計-->
    <sub-total></sub-total>
    <!--神腦幣-->
    <bonus-point></bonus-point>
    <!--活動折抵-->
    <cart-discount></cart-discount>
    <!--運費-->
    <ship-fare></ship-fare>
    <!--應付總金額-->
    <total-amount></total-amount>
    <div class="divider"></div>
    <!-- 實際折抵金額區 -->
    <discount-item-block></discount-item-block>
    <div class="divider"></div>
    <!-- 本次訂單交易實付金額 ; 有折扣抵扣才顯示 -->
    <real-amount v-if="isShowDiscountItem"></real-amount>
  </div>
</template>

<script>
import { isShowDiscountItem } from '@/helpers/payer-info';
import BonusPoint from '@/components/desktop/step1/amount-detail/child/bonus-point.vue';
import CartDiscount from '@/components/desktop/step1/amount-detail/child/cart-discount.vue';
import RealAmount from '@/components/desktop/step1/amount-detail/child/real-amount.vue';
import ShipFare from '@/components/desktop/step1/amount-detail/child/ship-fare.vue';
import SubTotal from '@/components/desktop/step1/amount-detail/child/sub-total.vue';
import TotalAmount from '@/components/desktop/step1/amount-detail/child/total-amount.vue';
import DiscountItemBlock from '@/components/desktop/step1/amount-detail/child/discount-item-block.vue';

export default {
  name: 'amount-detail',
  components: {
    SubTotal,
    BonusPoint,
    CartDiscount,
    ShipFare,
    TotalAmount,
    DiscountItemBlock,
    RealAmount,
  },
  computed: {
    isShowDiscountItem,
  },
};
</script>
