<template>
  <div class="row">
    <div class="alert alert-default alert-sm clearfix">
      <span class="spacing sm middle">* 限用台灣核發信用卡</span>
      <i class="icon icon-visa"></i>
      <i class="icon icon-master-card"></i>
      <i class="icon icon-jcb"></i>
      <p class="notice fn-sm spacing sm">
        * 當使用「信用卡紅利折抵」方式付款，若您單筆訂單中有多品項商品，後續辦理退貨僅能受理整筆訂單退貨。
      </p>
    </div>
    <div class="clearfix js-step2-staging"></div>
    <div class="alert alert-default js-step2-staging">
      <p class="dark">
        信用卡紅利折抵，接受
        <b class="value spacing sm">10</b>
        家銀行：
        <a
          href="https://event.senao.com.tw/20170103_bank_page3/"
          target="_blank"
          class="underline inverted spacing fn-sm">
          (詳細說明)
        </a>
      </p>
      <p class="fn-sm">
        永豐銀行、兆豐國際商銀、元大銀行、第一銀行、上海銀行、華南銀行、台新銀行、安泰商業銀行、合作金庫銀行、國泰世華
      </p>
    </div>
    <div class="row credit-card-area">
      <creditcard-info v-if="!isRtype"></creditcard-info>
    </div>
  </div>
</template>

<script>
import CreditcardInfo from '@/components/desktop/step1/payment-method/common/creditcard-info.vue';

export default {
  name: 'creditcard-bonus',
  inject: ['$validator'],
  components: { CreditcardInfo },
  props: {
    isRtype: Boolean,
  },
};
</script>
