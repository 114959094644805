<template>
  <div>
    <payer-info :module="module"></payer-info>
  </div>
</template>

<script>
import validateCleanError from '@/mixins/validate-clean-error';
import PayerInfo from '@/components/desktop/step1/payer-info/child/payer-info.vue';

export default {
  name: 'creditcard-payer',
  inject: ['$validator'],
  components: {
    PayerInfo,
  },
  data: () => ({
    /** module 名稱 */
    module: 'CardInfo',
  }),
  mixins: [validateCleanError],
};
</script>
