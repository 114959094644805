<template>
  <modal
    :isModalStatic="true"
    :isHideHeaderCloseButton="true"
    componentName="GoLoginModal"
    firstButtonText="取消"
    secondButtonText="確定"
    @secondFn="redirectToLogin">
    <template v-slot:body>
      <h3 :class="isMobile ? 'text-center' : 'align center'">您已是神腦生活會員請直接登入</h3>
    </template>
  </modal>
</template>
<script>
import { redirectToLogin } from '@/store/modules/step1/cart-detail';
import Modal from '@/components/common/common-modal.vue';
import { isMobile } from '@/helpers/is-from-app';

export default {
  name: 'GoLoginModal',
  components: {
    Modal,
  },
  computed: {
    isMobile,
  },
  methods: {
    redirectToLogin,
  },
};
</script>
