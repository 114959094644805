<template>
  <div class="css-tr">
    <div class="css-th align right w-f8"></div>
    <div class="css-td">
      <p class="sub spacing bottom">
        <input
          class="checkbox"
          id="card-keep"
          name="agreeCardKeep"
          :disabled="isRtype"
          type="checkbox"
          v-model="cardKeep"
          v-validate="{ required: isCycle }" />
        <label class="checkbox-label" for="card-keep">
          {{ message }}
        </label>
      </p>
      <p class="notice fn-sm">
        為確保網路交易安全，您了解本公司得就本件交易資料向發卡銀行及持卡人照會是否屬實，核對無誤後付款才算完成。
        <br />
        提醒您，如有冒用他人信用卡或其他個人資料而交易者，經查獲必移送法辦。
      </p>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'remember-creditcard',
  inject: ['$validator'],
  props: {
    isRtype: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardKeep: createComputed('CardInfo', 'cardKeep'),
    isCycle: createComputed('CardInfo', 'isCycle'),
    message() {
      return this.isCycle ? '記住信用卡資訊，以供週期購續訂、下次訂購時使用。' : '記住信用卡資訊下次使用';
    },
  },
};
</script>
