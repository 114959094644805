<template>
  <div class="row">
    <div class="alert alert-default alert-sm clearfix">
      <span class="spacing sm middle">* 限用台灣核發信用卡</span>
      <i class="icon icon-visa"></i>
      <i class="icon icon-master-card"></i>
      <i class="icon icon-jcb"></i>
      <span class="spacing sm middle">系統自動帶出商品共同可接受分期數</span>
      <p class="notice fn-sm spacing sm">
        * 當使用「信用卡分期」方式付款，若您單筆訂單中有多品項商品，後續辦理退貨僅能受理整筆訂單退貨。
      </p>
    </div>
    <div class="row credit-card-area" v-if="!isRtype">
      <creditcard-info></creditcard-info>
    </div>
    <installment-list></installment-list>
  </div>
</template>

<script>
import CreditcardInfo from '@/components/desktop/step1/payment-method/common/creditcard-info.vue';
import InstallmentList from '@/components/desktop/step1/payment-method/creditcard-installment/child/installment-list.vue';

export default {
  name: 'creditcard-installment',
  inject: ['$validator'],
  components: {
    CreditcardInfo,
    InstallmentList,
  },
  props: {
    isRtype: Boolean,
  },
};
</script>
