<template>
  <div class="row">
    <h4>
      選擇申請期數
      <span class="sub">(每期金額僅為試算，分期實付金額以無卡分期服務商議定內容為準。)</span>
    </h4>
    <div class="css-table has-checkbox-list middle spacing top">
      <div class="css-tr" v-for="(item, index) in zingalaInstallments" :key="index">
        <div class="css-th text-center w-f2">
          <input
            type="radio"
            name="installment"
            :id="`installment_${item.period}_${item.rate}`"
            :value="index"
            v-model="installmentIndex"
            v-validate="'required'" />
        </div>
        <div class="css-td">
          <label :for="`installment_${item.period}_${item.rate}`">
            <b class="value spacing sm">{{ item.period }}</b>
            期
            <b class="value spacing sm">{{ $filters.percent(item.rate) }}</b>
            利率，每期約
            <span class="price spacing sm">{{ $filters.dollar(item.perMoney) }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="sub spacing top">
      <input
        class="checkbox"
        id="zingala-terms"
        type="checkbox"
        name="agreeZingalaCheck"
        v-model="isCheckZingalaTerms"
        v-validate="{ required: isZingalaPayment }" />
      <label class="danger checkbox-label" for="zingala-terms">
        我已閱讀及明白
        <div class="sub underline has-inline popover-hover">
          <div class="popover-nav">付款說明</div>
          <div class="popover">
            <zingala-pop-over />
          </div>
        </div>
        ，並同意資料依下列方式使用
      </label>
    </div>
    <div class="sub spacing left md">
      本人已明白並同意神腦生活在完成本訂單之目的下，將訂單相關資料轉予 銀角零卡-無卡分期 仲信資融股份有限公司
      進行蒐集、處理及利用，並理解後續仲信資融將與本人進行資料之確認、核對及更正。
    </div>
  </div>
</template>

<script>
import { commitMutation, commitMutations, getState, createComputed, renderState } from '@/helpers/vuex';
import { applySpec, nth, compose, tap, prop } from 'ramda';
import { paymentEnum } from '@/constant/payment-info';
import zingalaPopOver from '@/components/desktop/step1/payment-method/common/pop-over-items/Zingala.vue';

/** 所有分期資料
 * @returns {Array}
 */
const zingalaInstallments = getState('ZingalaInfo', 'installments');

/** 選擇分期的 index */
const installmentIndex = {
  get: getState('ZingalaInfo', 'installmentIndex'),
  set: (value) =>
    compose(
      commitMutations('ZingalaInfo'),
      applySpec({ period: prop('period'), perMoney: prop('perMoney') }),
      nth(value),
      getState('ZingalaInfo', 'installments'),
      tap(commitMutation('ZingalaInfo', 'installmentIndex'))
    )(value),
};

/**
 * 判斷付款方式是否為零卡分期
 * @returns {boolean}
 */
const isZingalaPayment = () => renderState('PaymentInfo', 'payment') === paymentEnum.ZingalaPayment;

export default {
  name: 'zingala-payment',
  inject: ['$validator'],
  components: {
    zingalaPopOver,
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    zingalaInstallments,
    installmentIndex,
    isCheckZingalaTerms: createComputed('ZingalaInfo', 'isCheckZingalaTerms'),
    isZingalaPayment,
  },
};
</script>
