<template>
  <div class="sub underline has-inline popover-hover">
    <div class="popover-nav">&nbsp;(說明)</div>
    <div class="popover">
      <component :is="renderMatchedComponent(paymentType)"></component>
    </div>
  </div>
</template>
<script>
import { paymentEnum } from '@/constant/payment-info';
import ApplePay from '@/components/desktop/step1/payment-method/common/pop-over-items/ApplePay.vue';
import EasyPay from '@/components/desktop/step1/payment-method/common/pop-over-items/EasyPay.vue';
import Jkos from '@/components/desktop/step1/payment-method/common/pop-over-items/Jkos.vue';
import Pi from '@/components/desktop/step1/payment-method/common/pop-over-items/Pi.vue';
import PlusPay from '@/components/desktop/step1/payment-method/common/pop-over-items/PlusPay.vue';
import TaiwanPay from '@/components/desktop/step1/payment-method/common/pop-over-items/TaiwanPay.vue';
import Zingala from '@/components/desktop/step1/payment-method/common/pop-over-items/Zingala.vue';

const props = {
  paymentType: Number,
};

export default {
  name: 'pop-over',
  props,
  methods: {
    renderMatchedComponent(paymentType) {
      const { jkosPayment, PiPayment, TWPayment, PlusPayPayment, EasyPayPayment, ApplePayPayment, ZingalaPayment } =
        paymentEnum;
      const map = new Map([
        [jkosPayment, Jkos],
        [PiPayment, Pi],
        [TWPayment, TaiwanPay],
        [PlusPayPayment, PlusPay],
        [EasyPayPayment, EasyPay],
        [ApplePayPayment, ApplePay],
        [ZingalaPayment, Zingala],
      ]);
      return map.get(paymentType);
    },
  },
};
</script>
