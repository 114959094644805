<template>
  <div class="total-item-box" v-if="isShowDiscountItem">
    <div class="total-item sp_text" v-for="(item, index) in getDiscountList" :key="index">
      <span class="label">{{ item.deductTxt }}</span>
      <span class="price">-{{ item.deduct | dollar }}</span>
    </div>
  </div>
</template>

<script>
import { getState } from '@/helpers/vuex';
import { dollar } from '@/helpers/format/dollar';
import { isShowDiscountItem } from '@/helpers/payer-info';

const getDiscountList = getState('AmountDetail', 'getDiscountList');

const computed = {
  isShowDiscountItem,
  getDiscountList,
};

export default {
  name: 'discount-item-block',
  filters: {
    dollar,
  },
  computed,
};
</script>
