import { ref, computed } from 'vue';
import { renderGetter, commitMutation } from '@/helpers/vuex';
import { getInfiniteLabelText, getTimesLabel, showTimesLabel } from '@/helpers/cycle-order-content';

/**
 * useCycle
 * @param {Boolean} props.isMobile
 * @param {Object} props.item
 * @param {Number} props.itemIndex
 */
export function useCycle(props) {
  const isShowCycleOrder = computed(() => Object.keys(props.item).includes('interval'));
  const userSelectCycleInterval = ref(String(props.item?.interval));
  const renderCycleIntervalText = computed(() => String(userSelectCycleInterval.value));
  const userSelectCycleTimes = ref(Number(props.item?.times));
  const renderCycleTimesText = ref(String(props.item?.times));
  const infiniteTimesValue = 0;
  let cloneInfiniteLabelText = '';
  const getterCartDetail = () => renderGetter('CartDetail', 'getterCartDetail');

  // for cycle-order-content.vue
  const isShowCycleLimitedTimesType = computed(() => showTimesLabel(props.item.allTimes, props.item.times) === '');
  const showCycleTimesText = computed(() => getInfiniteLabelText(props.item));

  /**
   * isCycleLimitedPeriodTimesType
   * @param  {number} value
   * @return {boolean}
   */
  const isCycleLimitedPeriodTimesType = (value) => Number(value) > infiniteTimesValue;

  /**
   * formatRenderCycleTimesText
   * @return {string}
   */
  const formatRenderCycleTimesText = () => {
    renderCycleTimesText.value = isCycleLimitedPeriodTimesType(userSelectCycleTimes.value)
      ? `${userSelectCycleTimes.value}次`
      : cloneInfiniteLabelText;
  };

  /**
   * filterCyclePeriodTimes
   * @param {array} allTimes
   * @returns {array}
   */
  const filterCyclePeriodTimes = (allTimes) => {
    const cloneAndSetPeriodTimeTxt = allTimes.map((time) => {
      const isIncludeLabel = Object.keys(time).includes('label');
      if (isIncludeLabel) {
        cloneInfiniteLabelText = time.label;
      }
      return {
        ...time,
        periodTimesTxt: getTimesLabel(allTimes, time.value),
      };
    });
    return cloneAndSetPeriodTimeTxt;
  };

  /**
   * setValueToCycleParams
   * @param {string} value
   * @param {string} cycleFlag
   */
  const setValueToCycleParams = (value, cycleFlag) => {
    const cartDetail = getterCartDetail();
    if (!Array.isArray(cartDetail)) {
      throw new Error('getterCartDetail must return an array');
    }
    const cloneCartDetail = getterCartDetail().map((item) => ({ ...item }));
    if (typeof cloneCartDetail[props.itemIndex] === 'object') {
      cloneCartDetail[props.itemIndex][cycleFlag] = Number(value);
      commitMutation('CartDetail', 'cartDetail', cloneCartDetail);
    }
  };

  /**
   * handleCommonCycleType
   * @param {string} value
   * @param {string} cycleFlag
   */
  const handleCommonCycleType = (value, cycleFlag) => {
    setValueToCycleParams(value, cycleFlag);
    if (cycleFlag === 'interval') {
      userSelectCycleInterval.value = value;
      return;
    }
    userSelectCycleTimes.value = value;
    formatRenderCycleTimesText();
  };

  const handleCommonCycleInterval = (event) => {
    const { value } = event.target;
    handleCommonCycleType(value, 'interval');
  };

  const handleCommonCycleTimes = (event) => {
    const { value } = event.target;
    handleCommonCycleType(value, 'times');
  };

  return {
    isShowCycleOrder,
    renderCycleIntervalText,
    renderCycleTimesText,
    filterCyclePeriodTimes,
    handleCommonCycleType,
    handleCommonCycleInterval,
    handleCommonCycleTimes,
    isShowCycleLimitedTimesType,
    showCycleTimesText,
  };
}
