<template>
  <div v-if="isNotMajorItem" class="event">
    <p :class="{ 'no-discount': isInactiveDiscountItem }">
      <span :class="cartTagDisplay[item.cartTag]?.style">
        {{ cartTagDisplay[item.cartTag]?.text }}
      </span>
      <span class="text">{{ item.discountName }}</span>
    </p>
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'CartTag',
  props: {
    item: Object,
  },
  setup(props) {
    const MAJOR_ITEM = '0';
    const DISCOUNT_ITEM = '1';
    const isInactiveDiscountItem = computed(() => props.item.selectPricingPlan && props.item.cartTag === DISCOUNT_ITEM);
    const isNotMajorItem = computed(() => props.item.cartTag !== MAJOR_ITEM);
    const defaultStyle = 'tag tag-mini';
    const cartTagDisplay = {
      // 主商品沒有tag
      1: {
        text: '活動折扣',
        style: defaultStyle.concat(' ', 'tag-event-outline'),
      },
      2: {
        text: '加購品',
        style: defaultStyle.concat(' ', 'tag-secondary-outline'),
      },
      3: {
        text: '贈品',
        style: defaultStyle.concat(' ', 'tag-extra-outline'),
      },
      4: {
        text: '組合品',
        style: defaultStyle.concat(' ', 'tag-blue-outline'),
      },
    };
    return {
      cartTagDisplay,
      isNotMajorItem,
      isInactiveDiscountItem,
    };
  },
};
</script>
