export default {
  methods: {
    /**
     * 清除驗證的 errors
     */
    cleanErrors() {
      if (this.$validator === undefined) {
        return false;
      }

      this.$validator.pause();
      return this.$nextTick(() => {
        this.$validator.errors.clear();
        this.$validator.fields.items.forEach((field) => field.reset());
        this.$validator.fields.items.forEach((field) => this.errors.remove(field));
        this.$validator.resume();
      });
    },
  },
  created() {
    this.cleanErrors();
  },
};
