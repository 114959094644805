<template>
  <div>
    <h5>台灣Pay付款說明</h5>
    <ul class="has-type-decimal">
      <li>使用台灣Pay恕不適用分期付款。</li>
      <li>台灣Pay限使用金融卡/帳戶付款。</li>
      <li>台灣Pay付款有效時間：於訂單成立後 10 分鐘內，須完成付款。</li>
      <li>單筆訂單金額上限依各銀行單筆/單日交易限制。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TaiwanPay',
};
</script>
