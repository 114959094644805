<template>
  <div class="alert alert-default spacing top" data-panel="triplex">
    <div class="css-table pure middle">
      <!--使用統編記事本-->
      <invoice-note v-if="isLoginUser"></invoice-note>
      <!--公司統編-->
      <company-no :module="'InvoiceInfo'" :states="['companyNo']"></company-no>
      <!--公司抬頭-->
      <company-title :module="'InvoiceInfo'" :states="['companyTitle']"></company-title>
      <!--發票收件人-->
      <invoice-recipient :module="'InvoiceInfo'" :states="['recipient']"></invoice-recipient>
      <!--發票寄送地址-->
      <invoice-address :is-login-user="isLoginUser"></invoice-address>
      <!--鑑賞期寄出-->
      <paper-desc></paper-desc>
    </div>
  </div>
</template>

<script>
import CompanyNo from '@/components/desktop/step1/invoice-method/paper-invoice/child/company-no.vue';
import CompanyTitle from '@/components/desktop/step1/invoice-method/paper-invoice/child/company-title.vue';
import InvoiceAddress from '@/components/desktop/step1/invoice-method/paper-invoice/child/invoice-address.vue';
import InvoiceNote from '@/components/desktop/step1/invoice-method/paper-invoice/child/invoice-note.vue';
import InvoiceRecipient from '@/components/desktop/step1/invoice-method/paper-invoice/child/invoice-recipient.vue';
import PaperDesc from '@/components/desktop/step1/invoice-method/paper-invoice/child/paper-desc.vue';
import { isLoginUser } from '@/helpers/is-from-app';

export default {
  name: 'paper-invoice',
  inject: ['$validator'],
  components: {
    InvoiceNote,
    CompanyNo,
    CompanyTitle,
    InvoiceRecipient,
    InvoiceAddress,
    PaperDesc,
  },
  computed: {
    isLoginUser,
  },
};
</script>
