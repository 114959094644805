<template>
  <div class="css-tr">
    <div class="css-th align right w-f7">
      發票寄送地址
      <br />
      <br />
    </div>
    <div class="css-td">
      <!--鄉鎮市三連動-->
      <city-town-taiwan
        :module="moduleName"
        :states="stateNames"
        :island="true"
        :addressName="'invoiceAddressName'"
        :cityName="'invoiceCityName'"
        :townName="'invoiceTownName'"
        :idKey="'invoice'"></city-town-taiwan>
      <p class="sub spacing top sm" v-if="isLoginUser">
        <input class="checkbox" id="invoice-addNote" type="checkbox" v-model="isSave" />
        <label class="checkbox-label" for="invoice-addNote"> 設定為常用統編記事本 </label>
      </p>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import CityTownTaiwan from '@/components/desktop/step1/common/common-city-town-taiwan.vue';

export default {
  name: 'invoice-address',
  inject: ['$validator'],
  props: {
    isLoginUser: Boolean,
  },
  components: {
    CityTownTaiwan,
  },
  data: () => ({
    /** state 的 module 名稱 */
    moduleName: 'InvoiceInfo',
    /** 所綁定的 state */
    stateNames: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  }),
  computed: {
    isSave: createComputed('InvoiceInfo', 'alwaysUniform'),
  },
};
</script>
