<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      {{ prefix }}
    </div>
    <div class="css-td">
      <div v-if="isHasOrderEmail">{{ maskedEmail }}</div>
      <input
        v-else
        :name="errMsg"
        v-model="email"
        v-validate="'required|custom-email'"
        placeholder="請填寫E-Mail"
        class="input-lg"
        maxlength="40"
        autocomplete="off"
        type="text" />
      <p class="error">{{ errors.first(errMsg) }}</p>
    </div>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

const isHasOrderEmail = () => renderState('OrderInfo', 'isHasOrderMail');

export default {
  name: 'order-email',
  inject: ['$validator'],
  props: ['errMsg', 'prefix', 'module'],
  computed: {
    isHasOrderEmail,
    email: createComputed('OrderInfo', 'email'),
  },
  setup(props) {
    const { maskedEmail } = useMask(props);
    return { maskedEmail };
  },
};
</script>
