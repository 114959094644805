<template>
  <div class="panel panel-default cart-form">
    <div class="panel-head">訂購人資訊</div>
    <div class="panel-body">
      <div class="notice spacing bottom" v-if="!isLoginUser">訂單成立後將以您的手機作為帳號註冊成為神腦會員。</div>
      <div class="css-table pure middle">
        <order-name :module="module" :prefix="'中文姓名'" :err-msg="'訂購人姓名'"></order-name>
        <order-mobile :module="module" :prefix="'手機'" :err-msg="'訂購人手機'"></order-mobile>
        <order-email :module="module" :prefix="'電子信箱'" :err-msg="'訂購人電子信箱'"></order-email>
        <order-address :module="module" :states="orderAddressStates"></order-address>
      </div>
    </div>
    <go-login-modal />
  </div>
</template>

<script>
import OrderName from '@/components/desktop/step1/order-info/child/order-name.vue';
import OrderMobile from '@/components/desktop/step1/order-info/child/order-mobile.vue';
import OrderEmail from '@/components/desktop/step1/order-info/child/order-email.vue';
import OrderAddress from '@/components/desktop/step1/order-info/child/order-address.vue';
import GoLoginModal from '@/components/common/modals/go-login-modal.vue';
import { isLoginUser } from '@/helpers/is-from-app';

export default {
  name: 'order-info',
  inject: ['$validator'],
  components: {
    OrderName,
    OrderMobile,
    OrderEmail,
    OrderAddress,
    GoLoginModal,
  },
  data: () => ({
    module: 'OrderInfo',
    orderAddressStates: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  }),
  computed: {
    isLoginUser,
  },
};
</script>
