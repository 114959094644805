<template>
  <div class="alert alert-default spacing top" data-panel="donate">
    <span class="pull-left spacing right sm">發票捐贈：</span>
    <ul class="pull-left">
      <li v-for="(value, index) in donates" :key="index">
        <label>
          <input v-model="donateInvoice" :value="value.donateId" type="radio" name="donate" />
          {{ value.donateName }} (捐贈碼：{{ value.donateId }})
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'donate-invoice',
  computed: {
    donates: createComputed('InvoiceInfo', 'donateInvoiceList'),
    donateInvoice: createComputed('InvoiceInfo', 'donateInvoice'),
  },
  mounted() {
    this.donateInvoice = this.donates[0].donateId;
  },
};
</script>
