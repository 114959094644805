<template>
  <div>
    <h5>全盈+PAY說明</h5>
    <ul class="has-type-decimal">
      <li>使用全盈+PAY恕不適用分期付款。</li>
      <li>全盈+PAY接受信用卡及帳戶付款。</li>
      <li>消費可使用全家點數全額折抵。</li>
      <li>付款額度限制：依全盈+PAY官網說明。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PlusPay',
};
</script>
