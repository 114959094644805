<template>
  <div class="modal_box">
    <h3 class="align center">【 手機號碼認證 】</h3>
    <!--內容-->
    <div class="align center">
      <p class="spacing top stackable">
        請查看您的手機簡訊，並將收到的驗證碼填入「手機驗證碼」，
        <br />
        接著請點選「下一步」完成會員註冊
      </p>
      <form name="step2-member-registration" @submit.prevent="verifyAndGetMemberRegistration">
        <div class="form_2r spacing top bottom md">
          <span class="middle">驗證碼：</span>
          <input
            v-model.trim="authCode"
            type="text"
            class="middle"
            placeholder="請輸入您的手機驗證碼"
            autocomplete="one-time-code"
            maxlength="10" />
          &nbsp;
          <a v-if="isTimerBtn" class="btn btn-disabled w-f8" id="btnTimer" href="javascript:void(0);">
            <time class="countdown">{{ countDown }}</time>
            秒後重發
          </a>
          <a
            v-if="isSendCodeBtn"
            class="btn btn-secondary w-f8"
            id="btnSendCode"
            href="javascript:void(0);"
            @click="getSmsSend">
            重發驗證碼
          </a>
        </div>
        <p class="error notice" v-if="validateErrorMsg">
          {{ validateErrorMsg }}
        </p>
        <div class="row text-center text-muted">
          <ul class="has-inline text-left sticky">
            <li class="warning">注意事項：</li>
            <li>1. 請確認手機號碼是否輸入正確；是否拒收企業廣告簡訊</li>
            <li>2. 每日驗證碼最多申請3次</li>
            <li>3. 驗證碼於發送後30分鐘後自動失效</li>
            <li>4. 驗證成功系統將寄發預設密碼簡訊。</li>
          </ul>
        </div>
        <!--按鈕-->
        <div class="modal-footer">
          <button class="btn btn-default" data-dismiss="modal" :disabled="isTimerBtn" @click="closeModal">取消</button>
          <button type="submit" class="btn btn-primary w-f12" data-toggle="modal">下一步</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { commitMutation, createComputed, getState } from '@/helpers/vuex';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';

const closeModal = function () {
  return this.$emit('cancel');
};

const verifyAndGetMemberRegistration = function () {
  if (this.authCode === '') {
    commitMutation('RegisterSenaoMembership', 'validateErrorMsg', '請填寫正確的驗證碼');
    return;
  }
  sendGA4sEvent('sign_up_OTP', createRegistrationItem('簡訊驗證完成', 'cart'));
  this.$emit('get-member-registration');
};

const getSmsSend = function () {
  return this.$emit('get-sms-send');
};

export default {
  name: 'step2-member-registration',
  mixins: [memberRegistrationProcess],
  computed: {
    authCode: createComputed('RegisterSenaoMembership', 'authCode'),
    validateErrorMsg: getState('RegisterSenaoMembership', 'validateErrorMsg'),
  },
  methods: {
    getSmsSend,
    closeModal,
    verifyAndGetMemberRegistration,
  },
  created() {
    // 進畫面即開始倒數
    this.countDownTimer();
  },
};
</script>

<style scoped>
.form_2r .btn.w-f8 {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
