<template>
  <div class="panel-head">
    <div class="has-full flex">
      <span>付款方式</span>
      <img src="/images/icons/icon-megaphone-orange.svg" />
      <div class="slider marquee">
        <BaseMarquee />
      </div>
    </div>
  </div>
</template>

<script>
import BaseMarquee from '@/components/desktop/marquee.vue';

export default {
  name: 'payment-marquee',
  components: {
    BaseMarquee,
  },
};
</script>

<style scoped>
.panel-head > div {
  align-items: center;
  flex-wrap: nowrap;
}
img {
  width: 16px;
}
span {
  min-width: 80px;
}
.marquee {
  margin-bottom: 0;
  font-size: 13px;
  padding: 0 10px;
  background: #ffffff;
}
</style>
