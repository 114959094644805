<template>
  <div>
    <div class="css-table has-checkbox-list middle spacing top">
      <div v-for="(item, index) in installments" class="css-tr" :key="index">
        <div class="css-th text-center w-f2">
          <input
            type="radio"
            name="installment"
            :id="`installment_${item.period}_${item.rate}`"
            :value="index"
            v-model="installmentIndex"
            v-validate="'required'" />
        </div>
        <div class="css-td">
          <label :for="`installment_${item.period}_${item.rate}`">
            <b class="value spacing sm">{{ item.period }}</b>
            期
            <b class="value spacing sm">{{ item.rate | percent }}</b>
            利率， 每期約
            <span class="price spacing sm">{{ item.money | dollar }}</span>
          </label>
          <a
            @click="changeIndex(index), openModal('BankModal')"
            class="underline inverted fn-mini spacing left sm bankModalBtn">
            ({{ item.banks.length }}家銀行)
          </a>
        </div>
      </div>
    </div>
    <p v-if="errors.has('installment')" class="error">* 請選擇分期付款期數</p>
    <bank-modal :banks="banks" :period="period" :rate="rate"></bank-modal>
  </div>
</template>

<script>
import { applySpec, nth, compose, tap, prop } from 'ramda';
import { commitMutation, commitMutations, getState, renderState } from '@/helpers/vuex';
import { openModal } from '@/helpers/modal';
import { dollar, percent } from '@/helpers/format/dollar';
import BankModal from '@/components/desktop/step1/payment-method/creditcard-installment/child/child/bank-modal.vue';

/** 所有分期資料 */
const installments = getState('InstallmentInfo', 'installments');

/** 取得分期資訊 */
const installment = nth(
  renderState('InstallmentInfo', 'hoveredInstallment'),
  renderState('InstallmentInfo', 'installments')
);

/** 選擇分期的 index */
const installmentIndex = {
  get: getState('InstallmentInfo', 'installmentIndex'),
  set: (value) =>
    compose(
      commitMutations('CardInfo'),
      applySpec({ period: prop('period'), periodPay: prop('money') }),
      nth(value),
      getState('InstallmentInfo', 'installments'),
      tap(commitMutation('InstallmentInfo', 'installmentIndex'))
    )(value),
};

/** 每個分期所支援的銀行 */
const banks = function () {
  if (this.installments.length <= 0) return [];
  return this.installments[this.index].banks;
};

/** 每個分期數 */
const period = function () {
  if (this.installments.length <= 0) return 0;
  return this.installments[this.index].period;
};

/** 每個分期利率 */
const rate = function () {
  if (this.installments.length <= 0) return 0;
  return this.installments[this.index].rate;
};

const changeIndex = function (index) {
  this.index = index;
};

export default {
  name: 'installment-list',
  inject: ['$validator'],
  components: {
    BankModal,
  },
  data() {
    return {
      index: 0,
    };
  },
  filters: {
    dollar,
    percent,
  },
  computed: {
    installment() {
      return installment;
    },
    installments,
    installmentIndex,
    banks,
    period,
    rate,
  },
  methods: {
    changeIndex,
    openModal,
  },
  mounted() {
    const btns = document.getElementsByClassName('bankModalBtn');
    if (btns !== null) {
      for (let i = 0; i < btns.length; i++) {
        // eslint-disable-next-line no-new
        new Modal(btns[i]);
      }
    }
  },
};
</script>

<style scoped>
p.error {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #f61a13;
}
</style>
