<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      {{ prefix }}
    </div>
    <div class="css-td">
      <div v-if="isHasOrderMobile">
        {{ maskedMobile }}
        <span class="sub spacing">(此為神腦會員帳號，無法異動)</span>
      </div>
      <input
        v-else
        :name="errMsg"
        v-model="mobilePhone"
        @blur="validatePhone"
        v-validate="'required|mobile-phone'"
        placeholder="請填手機號碼"
        class="input-lg"
        maxlength="10"
        autocomplete="off"
        type="text" />
      <p class="error">{{ errors.first(errMsg) }}</p>
    </div>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import { checkMembership } from '@/helpers/login-check';
import { useMask } from '@/composables/mask';

const isHasOrderMobile = () => renderState('OrderInfo', 'isHasOrderMobile');

export default {
  name: 'order-mobile',
  inject: ['$validator'],
  props: ['errMsg', 'prefix', 'module'],
  computed: {
    isHasOrderMobile,
    mobilePhone: createComputed('OrderInfo', 'mobilePhone'),
    isLoginUser,
  },
  methods: {
    validatePhone() {
      !this.isLoginUser &&
        this.$validator.validate('訂購人手機', this.mobilePhone).then((isValid) => isValid && checkMembership());
    },
  },
  setup(props) {
    const { maskedMobile } = useMask(props);
    return { maskedMobile };
  },
};
</script>
