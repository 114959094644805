<template>
  <div class="css-tr">
    <div class="css-th align right w-f7"></div>
    <div class="css-td">
      <i class="icon icon-folder-plus"></i>
      <a class="underline fn-sm" @click="showInvoiceModal"> 使用統編記事本 </a>
    </div>
  </div>
</template>

<script>
import { compose, gt, __, prop } from 'ramda';
import { getAction, getState, commitMutation } from '@/helpers/vuex';

/**  vuex 裡的 invoices 的長度大於 0 則返回 true */
const hasInvoiceFn = compose(gt(__, 0), prop('length'), getState('InvoiceModalInfo', 'invoices'));

/** 打開 modal */
const openModal = function () {
  const modalElem = document.getElementById('invoice-modal');
  if (modalElem !== null) {
    /** use bootstrap native 寫法 */
    const myModal = new Modal(modalElem, { backdrop: 'static' });
    setTimeout(() => myModal.show(), 250);
  }
};

const showInvoiceModal = function () {
  /** 如果有統編資料或有打過[統編記事本] API */
  if (hasInvoiceFn() || this.hasLoadInvoice) {
    openModal();
  }
  /** 沒打過 [統編記事本] API 的情境 */
  if (!this.hasLoadInvoice) {
    /** load [統編記事本] API */
    getAction('InvoiceModalInfo', 'getInvoices')().then(() => {
      /** 寫入已 load 過[統編記事本] API 的紀錄 */
      commitMutation('InvoiceModalInfo', 'hasLoadInvoice', true);
      openModal();
    });
  }
};

export default {
  name: 'invoice-note',
  methods: {
    /** 判斷 component 的渲染條件 */
    showInvoiceModal,
  },
  computed: {
    /** 從 API 下載的 [統編記事本] */
    invoices: getState('InvoiceModalInfo', 'invoices'),
    /** 是否有打過 [統編記事本] API] */
    hasLoadInvoice: getState('InvoiceModalInfo', 'hasLoadInvoice'),
  },
};
</script>
