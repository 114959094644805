export const telToString = function (tel) {
  // 電話區碼 02
  let output = `${tel.area}`;

  // 電話區碼 + 號碼 02-12345678
  if (tel.number !== '') {
    output += `-${tel.number}`;
  }
  // 電話區碼 + 號碼 + 分機號碼 02-12345678#1111
  if (tel.ext !== '') {
    output += `#${tel.ext}`;
  }
  return output;
};

export const telToObject = function (tel) {
  const pattern = /^(0\d+)-(\d{6,8})(?:(?:#)(\d+))?$/;
  const result = pattern.exec(tel);
  if (!result) {
    return {
      area: '',
      number: '',
      ext: '',
    };
  }

  const getValue = function (data) {
    if (typeof data === 'undefined') {
      return '';
    }
    return data;
  };
  // 無分機號碼
  return {
    area: getValue(result[1]),
    number: getValue(result[2]),
    ext: getValue(result[3]),
  };
};
