<template>
  <div class="panel panel-default cart-form">
    <div class="panel-head">付款人資訊</div>
    <div class="panel-body">
      <!-- 付款人資訊：帶入「訂購人」資料 -->
      <!-- 註1. 信用卡快速結帳(不變動卡號的狀況下)，預設帶入上次的付款人資訊 -->
      <!-- 註2. ATM轉帳 & 門市繳費，預設帶入「會員資料」 -->
      <div class="css-table pure middle">
        <payer-name
          :module="module"
          :states="payerNameStates"
          :prefix="payerNamePrefix"
          :postfix="postfix"
          :err-msg="'payerName'"
          :validateRule="validateNameRule"
          :isRtype="isRtype"></payer-name>
        <payer-mobilephone
          :module="module"
          :states="payerMobilephoneStates"
          :name="'payerMobilephone'"
          :isRtype="isRtype"></payer-mobilephone>
        <payer-localphone
          :module="module"
          :states="payerLocalphoneStates"
          :phoneAreaName="'payerLocalPhoneArea'"
          :phoneName="'payerLocalPhone'"
          :phoneExtName="'payerLocalPhoneExt'"
          :isRtype="isRtype"></payer-localphone>
        <payer-email
          :module="module"
          :states="payerEmailStates"
          :emailName="'payerEmail'"
          :isRtype="isRtype"></payer-email>
        <payer-address :module="module" :states="payerAddressStates" :isRtype="isRtype"></payer-address>
        <remember-creditcard v-if="isShowCardKeep" :isRtype="isRtype"></remember-creditcard>
      </div>
    </div>
  </div>
</template>

<script>
import { compose, equals, prop, __ } from 'ramda';
import { renderState, getState, getGetter } from '@/helpers/vuex';
import { postfixLut } from '@/components/desktop/step1/payer-info/constant/payer-info.constant';
import { isShowCardKeepMsg } from '@/components/desktop/step1/payer-info/common/payer-info';
import PayerAddress from '@/components/desktop/step1/payer-info/child/payer-address.vue';
import PayerEmail from '@/components/desktop/step1/payer-info/child/payer-email.vue';
import PayerLocalphone from '@/components/desktop/step1/common/common-localphone.vue';
import PayerMobilephone from '@/components/desktop/step1/common/common-mobilephone.vue';
import PayerName from '@/components/desktop/step1/common/common-name.vue';
import RememberCreditcard from '@/components/desktop/step1/payer-info/child/remember-creditcard.vue';

/** 產生所需要的 postfix 訊息 */
export const makePostfix = compose(prop(__, postfixLut), getState('PaymentInfo', 'payment'));

/** 付款人表單是否為 [門取或行動支付] */
export const isOtherPayer = compose(equals('StoreInfo'));

/** 是否為 [0元訂單] */
export const isZeroOrder = compose(equals(0), getGetter('AmountDetail', 'realInstantAmount'));

export default {
  name: 'payer-info',
  inject: ['$validator'],
  components: {
    PayerName,
    PayerMobilephone,
    PayerLocalphone,
    PayerEmail,
    PayerAddress,
    RememberCreditcard,
  },
  data: () => ({
    /** <payer-name/> 所使用的 states */
    payerNameStates: ['name'],
    /** <payer-mobilephone/> 所使用的 states */
    payerMobilephoneStates: ['mobilePhone'],
    /** <payer-localphone/> 所使用的 states */
    payerLocalphoneStates: ['localPhoneArea', 'localPhone', 'localPhoneExt'],
    /** <payer-email/> 所使用的 states */
    payerEmailStates: ['email'],
    /** <payer-address/> 所使用的 states */
    payerAddressStates: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
    /** 中文姓名前綴 */
    payerNamePrefix: '中文姓名',
  }),
  props: [
    /** module 名稱 */
    'module',
  ],
  computed: {
    /** 姓名提示資訊 */
    postfix: makePostfix,
    validateNameRule() {
      return !(isOtherPayer(this.module) || isZeroOrder()) ? 'required|chinese-only' : 'required';
    },
    /** 是 R 身份且為[信用卡類] disable 付款人表單 */
    isRtype() {
      return renderState('PaymentInfo', 'isPayerReadOnly');
    },
    /** [門取或行動支付] 或 [0元訂單] 或未登入者不顯示「記住信用卡資訊下次使用」component */
    isShowCardKeep() {
      return isShowCardKeepMsg(this.module);
    },
  },
  mounted() {
    this.$validator.validate();
  },
};
</script>
