<template>
  <div v-if="isShowCycleOrder">
    <!-- 桌機版 at cart-detail.vue -->
    <div v-if="!isMobile" class="cart_cycle">
      <img alt="週期購" src="/images/icons/icon_cycle.png" />
      <div class="cart_cycle_text">
        <p>
          每隔
          <span class="cycle_s">{{ item.interval }}天</span>
          為您配送
          <span class="cycle_s">{{ productQty }}組</span>
          商品；訂購次數含本次，<span v-if="isShowCycleLimitedTimesType">總共訂購&nbsp;</span>
          <span class="cycle_s">{{ showCycleTimesText }} </span>
          。
        </p>
        <p>
          <span class="cycle_s">限使用信用卡一次付款</span>
          ，將依您選擇的訂購週期次數，自動續訂購該項商品。
        </p>
      </div>
    </div>
    <!-- 手機版 at default-view.vue -->
    <div class="cyclebuy-container-row" v-else>
      <div class="cyclebuy-header-row">
        <p>
          <b class="notice">每隔{{ item.interval }}天</b>配送<b class="notice">{{ productQty }}組</b>商品；<span
            v-if="isShowCycleLimitedTimesType"
            >總共訂購(含本次)&nbsp;</span
          ><b class="notice">{{ showCycleTimesText }}</b
          >。<b class="notice">限使用信用卡一次付款</b>，將依您選擇的週期間隔，自動為您續訂該商品。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useCycle } from '@/composables/cycleOrder';

const props = {
  productQty: Number,
  isMobile: Boolean,
  item: Object,
};

export default {
  name: 'cycle-order-content',
  props,
  setup(props) {
    const { isShowCycleOrder, isShowCycleLimitedTimesType, showCycleTimesText } = useCycle(props);
    return { isShowCycleOrder, isShowCycleLimitedTimesType, showCycleTimesText };
  },
};
</script>
