<template>
  <div class="list-wrapper">
    <ul class="marquee-vertical">
      <li v-for="(item, index) in getMarquee" :key="index">
        <a :href="item.hyperlink" :target="item.target" :title="item.message">
          {{ item.message }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import marquee from '@/mixins/cart/marquee';

export default {
  mixins: [marquee],
};
</script>

<style scoped>
.list-wrapper {
  display: flex;
  overflow: hidden;
}

.marquee-vertical {
  position: relative;
  display: flex;
  white-space: nowrap;
  animation: marquee 50s linear infinite;
  margin-bottom: 0;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
