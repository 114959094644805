<template>
  <div class="total-item">
    <span class="label">運費：</span>
    <span class="price">{{ realShipFare | dollar }}</span>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { getGetter } from '@/helpers/vuex';

/** 本次實際運費 */
const realShipFare = getGetter('AmountDetail', 'realShipFare');

export default {
  name: 'ship-fare',
  filters: {
    dollar,
  },
  computed: {
    realShipFare,
  },
};
</script>
