<template>
  <div class="sub">
    <input class="checkbox" id="agree" type="checkbox" name="agreeCheck" v-model="agreeCheck" v-validate="'required'" />
    <label class="danger checkbox-label" for="agree">
      <span class="danger">若本人後續辦理退貨作業，同意由神腦國際代為處理發票及銷貨退回證明單，以加速退貨退款作業</span>
    </label>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'notice-item',
  inject: ['$validator'],
  computed: {
    agreeCheck: createComputed('PaymentInfo', 'agreeCheck'),
  },
};
</script>
