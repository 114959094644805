<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      收件地址
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="css-td pulldownChangeTaiwan-group">
      <city-town-taiwan
        :module="module"
        :states="states"
        :island="false"
        :addressName="'recipientAddressName'"
        :cityName="'recipientCityName'"
        :townName="'recipientTownName'"
        :idKey="'recipient'"></city-town-taiwan>
      <p class="notice fn-sm spacing top mini">(暫無提供外島配送服務，且不接受郵政信箱)</p>
      <p class="sub">提醒您：上述資料請完整填寫正確資訊，避免影響到訂單相關權益</p>
      <p class="sub spacing top sm" v-if="isLoginUser">
        <input class="checkbox" id="recipient-addNote" type="checkbox" v-model="isSave" />
        <label class="checkbox-label" for="recipient-addNote"> 加入收件人記事本 </label>
      </p>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import CityTownTaiwan from '@/components/desktop/step1/common/common-city-town-taiwan.vue';

export default {
  name: 'recipient-address',
  inject: ['$validator'],
  components: {
    CityTownTaiwan,
  },
  props: [
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
  ],
  computed: {
    isSave: createComputed('RecipientInfo', 'isSave'),
    isLoginUser,
  },
};
</script>
