<template>
  <div class="modal fade" id="invoice-modal">
    <!-- if Invoice data empty -->
    <div v-if="!hasInvoice" class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 style="font-weight: normal">尚未設定統編資訊</h5>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" data-dismiss="modal">關閉</button>
        </div>
      </div>
    </div>
    <!-- has Invoice data> -->
    <div v-if="hasInvoice" class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3>【 請點選統一編號 】</h3>
        </div>
        <div class="modal-body">
          <div class="css-table middle stackable">
            <div class="css-tr">
              <div class="css-th">公司編號</div>
              <div class="css-th">公司抬頭</div>
              <div class="css-th">發票收件人</div>
              <div class="css-th">地址</div>
              <div class="css-th align center w-f5">選取</div>
            </div>
            <div v-for="(item, index) in invoices" :key="index" class="css-tr">
              <div class="css-td">{{ item.number }}</div>
              <div class="css-td">{{ item.title }}</div>
              <div class="css-td">{{ maskName(item.receipt) }}</div>
              <div class="css-td">
                {{ getCityById(item.city) }}{{ getTownById(item.city, item.town) }}{{ maskAddress(item.address) }}
              </div>
              <div class="css-td align center w-f5">
                <button @click="onSelect(index, item)" class="btn btn-default btn-sm">
                  {{ item.caption }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" data-dismiss="modal">取消</button>
          <button class="btn btn-primary" @click="onConfirmSelect" :data-dismiss="dataDismiss">確認選取</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { applySpec, compose, prop, when, find, propEq, propOr } from 'ramda';
import { commitMutations, getState, getGetter, renderState } from '@/helpers/vuex';
import { makeIsWindowClosable, makeOnSelect } from '@/helpers/modal';
import { maskName, maskAddress } from '@/helpers/mask';

/** 若已經選取 [統編收件人]，才可以關閉視窗 */
const isWindowClosable = makeIsWindowClosable('InvoiceModalInfo');

/** 將所選擇的 [統編收件人] 存 InvoiceInfo store */
const saveInvoiceInfo = compose(
  commitMutations('InvoiceInfo'),
  applySpec({
    companyNo: prop('number'),
    companyTitle: prop('title'),
    addressCityId: prop('city'),
    addressTownId: prop('town'),
    address: prop('address'),
    recipient: prop('receipt'),
  }),
  getState('InvoiceModalInfo', 'selectedItem')
);

/** 確認選取 */
/** 當有選取任何一個 [統編收件人]，才可以繼續 */
const onConfirmSelect = when(isWindowClosable, saveInvoiceInfo);

/** 由 [城市代碼] 抓 [城市名稱] */
const getCityById = (cityId) =>
  compose(propOr('', 'name'), find(propEq('id', cityId)), getGetter('CityTownTaiwan', 'cities'))();

/** 由 [城市代碼] 與 [鄉鎮代碼] 抓 [鄉鎮名稱] */
const getTownById = (cityId, townId) =>
  compose(
    propOr('', 'name'),
    find(propEq('id', townId)),
    propOr([], 'districts'),
    find(propEq('id', cityId)),
    getGetter('CityTownTaiwan', 'cities')
  )();

export default {
  name: 'invoice-modal',
  computed: {
    /** 從 API 下載的 [統編記事本] */
    invoices: getState('InvoiceModalInfo', 'invoices'),
    /** 關閉 [統編記事本] */
    dataDismiss: getState('InvoiceModalInfo', 'dataDismiss'),
    /** 判斷統編是否有資料 */
    hasInvoice() {
      return renderState('InvoiceModalInfo', 'invoices').length > 0;
    },
  },
  methods: {
    onConfirmSelect,
    /** 選取 [統編收件人] */
    onSelect: makeOnSelect('InvoiceModalInfo', 'toggleTitle'),
    maskName,
    maskAddress,
    getCityById,
    getTownById,
  },
};
</script>
