<template>
  <div class="alert alert-warning alert-sm">
    * 電子票券將於付款完成取得序號後，將發送通知至
    <b class="notice">付款人</b>
    的
    <b class="notice">Mail</b>
    及
    <b class="notice">手機簡訊</b>
    ，消費時請向店家出示票券號碼使用。
    <br />
    * 若需查看票券訊息，請登入會員，至訂單紀錄>
    <a class="underline" :href="ticketUrl" target="_blank"> 電子票券 </a>
    查看
    <br />
    * 電子票券僅限信用卡一次付款及神腦門市繳費
  </div>
</template>

<script>
const url = process.env.VUE_APP_ONLINE;
export default {
  name: 'vtp-desc',
  data() {
    return {
      ticketUrl: `${url}/eTicket`,
    };
  },
};
</script>
