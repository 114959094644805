<template>
  <div class="css-tr">
    <div class="css-th align right w-f8">
      <b class="notice">*</b>
      地址
    </div>
    <div class="css-td">
      <city-town-taiwan
        :module="module"
        :states="states"
        :island="true"
        :addressName="'payerAddressName'"
        :cityName="'payerCityName'"
        :townName="'payerTownName'"
        :idKey="'payer'"
        :message="message"
        :isRtype="isRtype"></city-town-taiwan>
    </div>
  </div>
</template>

<script>
import { isShowCardKeepMsg } from '@/components/desktop/step1/payer-info/common/payer-info';
import CityTownTaiwan from '@/components/desktop/step1/common/common-city-town-taiwan.vue';

export default {
  name: 'payer-address',
  inject: ['$validator'],
  props: [
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
    'isRtype',
  ],
  components: { CityTownTaiwan },
  computed: {
    message() {
      return isShowCardKeepMsg(this.module) ? '(請填信用卡帳單地址)' : '';
    },
  },
};
</script>
