<template>
  <div class="bar gray spacing top">
    <label>請輸入自然人憑證條碼：</label>
    <input
      class="input-lg"
      maxlength="16"
      type="text"
      autocomplete="off"
      name="id_device_code"
      v-model="value"
      v-validate.initial="'required|invoice-id-device'" />
    <span class="sub spacing">(共 16 碼，2 碼大寫英文 + 14 碼數字)</span>
    <p v-if="errors.has('id_device_code')" class="error">* {{ errors.first('id_device_code') }}</p>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'natural-vehicle',
  inject: ['$validator'],
  computed: {
    value: createComputed('InvoiceInfo', 'idDeviceCode'),
  },
};
</script>

<style scoped>
p.error {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #f61a13;
}
</style>
