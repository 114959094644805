<template>
  <div class="total billboard">
    <div class="total-item total-zc">
      <span class="label" v-if="isZingalaPayment"> 本次應付總金額 </span>
      <span class="label" v-else> 本次實付金額 </span>
      <span class="price">{{ instantAmount | dollar }}</span>
      <span class="danger" v-if="isZingalaPayment"> (分期實付金額以與服務商申請議定內容為準) </span>
    </div>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { getGetter } from '@/helpers/vuex';

export default {
  name: 'total-amount',
  filters: {
    dollar,
  },
  props: {
    isZingalaPayment: Boolean,
  },
  computed: {
    instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
  },
};
</script>
