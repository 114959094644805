<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">市話</div>
    <div class="css-td">
      <input
        v-model="localPhoneArea"
        v-validate="'local-phone-area'"
        :name="phoneAreaName"
        :disabled="isRtype"
        class="input-micro"
        maxlength="4"
        autocomplete="off"
        type="text" />
      <span class="divider">-</span>
      <input
        v-if="isNotMasked"
        v-model="localPhone"
        v-validate="'local-phone'"
        :name="phoneName"
        :disabled="isRtype"
        maxlength="8"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedTel" type="text" @focus="isNotMasked = true" />
      <span class="divider">分機</span>
      <input
        v-model="localPhoneExt"
        v-validate="'local-phone-ext'"
        :name="phoneExtName"
        :disabled="isRtype"
        class="input-mini"
        maxlength="6"
        autocomplete="off"
        type="text" />
      <p class="error">{{ errors.first(phoneAreaName) }}</p>
      <p class="error">{{ errors.first(phoneName) }}</p>
      <p class="error">{{ errors.first(phoneExtName) }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { index } from '@/components/desktop/step1/common/constant/common-localphone.constant';
import { useMask } from '@/composables/mask';

export default {
  name: 'recipient-localphone',
  inject: ['$validator'],
  props: {
    /** 與 Vuex 綁定的 module 名稱 */
    module: {
      type: String,
      default: '',
    },
    /** 與 Vuex 綁定的 state 名稱 */
    states: {
      type: Array,
      default() {
        return ['localPhoneArea', 'localPhone', 'localPhoneExt'];
      },
    },
    phoneAreaName: {
      type: String,
      default: 'phoneAreaName',
    },
    phoneName: {
      type: String,
      default: 'phoneName',
    },
    phoneExtName: {
      type: String,
      default: 'phoneExtName',
    },
    isRtype: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** [市話區碼] */
    localPhoneArea: createAdvComputed(index.localPhoneArea),
    /** [市話] */
    localPhone: createAdvComputed(index.localPhone),
    /** [市話分機] */
    localPhoneExt: createAdvComputed(index.localPhoneExt),
  },
  created() {
    this.setIsNotMaskedByValue(this.localPhone);
  },
  setup(props) {
    const { maskedTel, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedTel, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
