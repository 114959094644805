<template>
  <div class="wrapper">
    <Checkout :class="formVisibilityformHidden"></Checkout>
    <Security v-if="showSecurity"></Security>
  </div>
</template>

<script>
import { getState } from '@/helpers/vuex';
import validateCleanError from '@/mixins/validate-clean-error';
import validator from '@/validators/desktop-step1';
import Checkout from '@/components/desktop/step1/step1.vue';
import Security from '@/components/desktop/security/security.vue';

validator();

export default {
  name: 'step1',
  mixins: [validateCleanError],
  components: {
    Checkout,
    Security,
  },
  computed: {
    /** 決定 dynamic component 要使用哪一個 component */
    dynamicComponent: getState('Step1Component', 'dynamicComponent'),
    showSecurity() {
      return this.dynamicComponent === 'Security';
    },
    formVisibilityformHidden() {
      return { hidden: this.showSecurity };
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
