<template>
  <div>
    <div class="btn-group">
      <button
        class="btn btn-secondary btn-md pull-center w-fix-cart"
        @click="onConfirmPayment"
        :disabled="isSubmitBtnDisabled">
        確認付款
      </button>
    </div>
    <zingala-info-modal ref="zingalaModal" />
    <register-info-modal
      :mobile-phone="mobilePhone"
      @get-sms-send="getSmsSend"
      @get-member-registration="getMemberRegistration"></register-info-modal>
  </div>
</template>

<script>
import { prop, compose, anyPass } from 'ramda';
import { step1ComponentEnum } from '@/store/modules/step1/step1-component-enum';
import { commitMutation, getState, renderState, renderGetter, getGetter } from '@/helpers/vuex';
import { equalProps } from '@/helpers/ramdaex';
import { setOrder } from '@/helpers/format/order-param';
import { isLoginUser } from '@/helpers/is-from-app';
import { openModal } from '@/helpers/modal';
import { paymentEnum } from '@/constant/payment-info';
import { getMessage, getPayerInfoMessage } from '@/validators/common-validator';
import { completeMerchantValidation } from '@/helpers/apple-pay';
import ZingalaInfoModal from '@/components/common/modals/zingala-info-modal.vue';
import orderProcessResponse from '@/mixins/cart/finished/order-process-response';
import { memberRegistrationProcess } from '@/mixins/cart/finished/member-registration-process';
import submitOrder from '@/api/order-process';
import validator from '@/validators/mobile-step2';
import RegisterInfoModal from '@/components/desktop/step1/register-info-modal/modal.vue';

validator();

/** 是否為 [信用卡紅利] */
const isCreditBonus = equalProps('creditCardBonus', paymentEnum);

/** 是否為 [信用卡一次] */
const isCreditOnetime = equalProps('creditCardOnetime', paymentEnum);

/** 是否為 [信用卡分期] */
const isCreditInstallment = equalProps('creditCardInstallment', paymentEnum);

/** 判斷是否為 [信用卡] 付款方式 */
const isCreditCard = compose(
  anyPass([isCreditBonus, isCreditOnetime, isCreditInstallment]),
  getState('PaymentInfo', 'payment')
);

const openSecurityLoadingAndFetchOrderProcess = () => {
  commitMutation('Step1Component', 'dynamicComponent', prop('security', step1ComponentEnum));
};

const openLoadingAndFetchOrderProcess = function () {
  this.$store.dispatch('Loading/updateLoading', { isLoading: true });
  submitOrder(setOrder())
    .then((res) => this.orderProcessResponseToNext(res))
    .catch((error) => this.catchResponse(error));
};

export default {
  name: 'confirm-payment',
  inject: ['$validator'],
  mixins: [orderProcessResponse, memberRegistrationProcess],
  components: {
    RegisterInfoModal,
    ZingalaInfoModal,
  },
  computed: {
    instantAmount: getGetter('AmountDetail', 'realInstantAmount'),
    isSubmitBtnDisabled: getState('RegisterSenaoMembership', 'isSubmitBtnDisabled'),
    isCreditCard,
    isLoginUser,
    mobilePhone: getState('OrderInfo', 'mobilePhone'),
  },
  methods: {
    openLoadingAndFetchOrderProcess,
    /** 確認付款 */
    onConfirmPayment() {
      this.$validator.validateAll().then(() => this.showSecurity());
    },
    // 檢查 vee-validator 抓出的錯誤 ; 顯示 [結帳] 或 [授權處理]
    showSecurity() {
      if (this.errors.items.length) {
        const focusInputName = this.errors.items[0].field;
        const errorItemName = this.errors.items[0];
        // focus 該 input element
        const thisElName = this.$root.$el.querySelector(`[name=${focusInputName}]`);
        // 結帳金額 > 0 全部表單需驗證
        if (this.instantAmount > 0) {
          const allMessage = getMessage(errorItemName);
          alert(allMessage);
          if (thisElName !== null) {
            thisElName.focus();
          }
          return;
        }
        // 零元訂單只需驗證付款人表單
        const payerInfoMsg = getPayerInfoMessage(errorItemName);
        alert(payerInfoMsg);
        if (thisElName !== null) {
          thisElName.focus();
        }
        return;
      }
      // 自行寫的驗證 ; 付款方式需為信用卡類且檢查信用卡片日期是否過期
      // 如折扣金將訂單折為0元，不應檢查信用卡片是否過期
      if (this.instantAmount > 0) {
        const isValidateExpired = renderGetter('CardInfo', 'isValidateExpired');
        if (this.isCreditCard && isValidateExpired) {
          // 判斷是否為週期購商品及驗證卡片有效期限
          const isCycle = renderState('CardInfo', 'isCycle') === 1;
          const cardExpiredErrorMsg = isCycle
            ? '請您確認提供之信用卡有效年月，是否大於您此次購買週期購預約的年月效期。'
            : '請您確認提供之信用卡有效年月。';
          alert(cardExpiredErrorMsg);
          return;
        }
      }
      if (this.isSubmitBtnDisabled) {
        return;
      }
      this.$emit('is-submit-btn-disabled', true);
      const isUserMember = renderState('RegisterSenaoMembership', 'isUserMember', true);
      if (isUserMember) {
        openModal('GoLoginModal');
        return;
      }
      if (!this.isLoginUser) {
        openModal('RegisterModal');
        return;
      }
      this.onConfirmPaymentAndOrderProcess();
    },
    onConfirmApplePay() {
      this.$emit('is-submit-btn-disabled', false);
      completeMerchantValidation();
      NCPayment.applePay.getPaymentToken((error, paymentToken) => {
        // success: { "status": 0, "errors": null }
        if (error === null) {
          commitMutation('PaymentInfo', 'applePayPaymentToken', paymentToken);
          openSecurityLoadingAndFetchOrderProcess();
        }
      });
    },
    onConfirmPaymentAndOrderProcess() {
      const isUserPickApplePay = renderState('PaymentInfo', 'payment') === 500;
      if (window.ApplePaySession && isUserPickApplePay) {
        this.onConfirmApplePay();
        return;
      }
      /** 信用卡訂單進入 SSL loading bar */
      if (isCreditCard() && this.instantAmount > 0) {
        openSecurityLoadingAndFetchOrderProcess();
        return;
      }
      this.openLoadingAndFetchOrderProcess();
    },
  },
};
</script>
