<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      地址
      <br />
      <br />
    </div>
    <div class="css-td pulldownChangeTaiwan-group">
      <city-town
        :module="module"
        :states="states"
        :island="true"
        :addressName="'recipientAddressName'"
        :cityName="'recipientCityName'"
        :townName="'recipientTownName'"
        :idKey="'order'"></city-town>
      <p class="error hide">請填寫完整地址</p>
      <p class="sub spacing top sm">欲修改中文姓名請來電至客服專線 02-8978-6068，其他資料修改請至會員中心 > 會員資料</p>
    </div>
  </div>
</template>

<script>
import { isLoginUser } from '@/helpers/is-from-app';
import CityTown from '@/components/desktop/step1/order-info/child/order-city-town.vue';

export default {
  name: 'order-address',
  components: {
    CityTown,
  },
  props: ['module', 'states'],
  computed: {
    isLoginUser,
  },
};
</script>
