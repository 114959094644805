<template>
  <div class="css-tr">
    <div class="css-th align right w-f7">發票收件人</div>
    <div class="css-td">
      <input
        v-model="recipient"
        class="input-lg"
        minlength="2"
        maxlength="20"
        autocomplete="off"
        name="order_invoice_name"
        v-validate="'required'"
        type="text" />
      <p v-if="errors.has('order_invoice_name')" class="error">* {{ errors.first('order_invoice_name') }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { index } from '@/components/desktop/step1/invoice-method/paper-invoice/child/constant/invoice-recipient.constant';

export default {
  name: 'invoice-recipient',
  inject: ['$validator'],
  props: [
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
  ],
  computed: {
    /** [發票收件人] state */
    recipient: createAdvComputed(index.recipient),
  },
};
</script>
