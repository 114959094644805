<template>
  <div class="bar gray spacing top">
    <label>請輸入手機條碼：</label>
    <input
      maxlength="8"
      type="text"
      class="input-lg"
      autocomplete="off"
      name="mobile_device_code"
      v-model="value"
      v-validate.initial="'required|invoice-mobile-device'"
      key="mobile_device_code" />
    <span class="sub spacing">(共 8 碼，限數字與大寫英文，以 "/" 開頭)</span>
    <p v-if="errors.has('mobile_device_code')" class="notice fn-sm">* {{ errors.first('mobile_device_code') }}</p>
    <div class="sub">
      <input class="checkbox" id="agree" type="checkbox" v-model="isSaveDeviceCode" />
      <label class="danger checkbox-label" for="agree">
        <span>我同意紀錄本次手機條碼為常用載具</span>
      </label>
    </div>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'mobile-device',
  inject: ['$validator'],
  computed: {
    value: createComputed('InvoiceInfo', 'phoneDeviceCode'),
    isSaveDeviceCode: createComputed('InvoiceInfo', 'isSaveDeviceCode'),
  },
};
</script>

<style scoped>
p.error {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #f61a13;
}
</style>
