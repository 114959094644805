<template>
  <div>
    <ul class="has-pay-select clearfix">
      <li v-for="(item, key) in payments" :key="key" :class="{ active: isActive(item.paymentType) }">
        <label class="radio-inline spacing right" :class="{ jko: isMobilePayment(item.paymentType) }">
          <input
            type="radio"
            name="payment-type"
            v-model="payment"
            :value="item.paymentType"
            :disabled="isDisabled(item)"
            @change="fetchPayment(item.paymentType)" />
          {{ item.paymentName }}
          <pop-over v-if="isMobilePayment(item.paymentType)" :paymentType="item.paymentType"></pop-over>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { createDynamicComponent, dispatchAction, renderState } from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/payment-method/constant/payment-method.constant';
import { paymentEnum, paymentStatusEnum } from '@/constant/payment-info';
import { prop, propEq } from 'ramda';
import popOver from '@/components/desktop/step1/payment-method/common/pop-over.vue';

/** 所選擇的付款方式 (radio-button 預設選取)
 * @return: {Number} */
const payment = createDynamicComponent('PaymentInfo', 'payment', 'paymentMethod', componentLut);

/** active 藍底 className
 * @param: Number
 * @return: {Boolean} */
const isActive = function (item) {
  return item === this.payment;
};

/** for 行動支付 label class
 * @param: Number
 * @return: {Boolean} */
const isMobilePayment = (paymentType) => {
  const { jkosPayment, PiPayment, TWPayment, PlusPayPayment, EasyPayPayment, ApplePayPayment, ZingalaPayment } =
    paymentEnum;
  const mobilePaymentEnum = [
    jkosPayment,
    PiPayment,
    TWPayment,
    PlusPayPayment,
    EasyPayPayment,
    ApplePayPayment,
    ZingalaPayment,
  ];
  return mobilePaymentEnum.includes(paymentType);
};

/** 付款方式是否為 disable (目前用在分期付款)
 * @param: {Number} (0,1,2)
 * @return: {Boolean} */
const isDisabled = propEq('status', prop('disable', paymentStatusEnum));

/**
 * 信用卡分期 || 銀角中租零卡分期
 * @param {Number} paymentType
 */
const fetchPayment = (paymentType) => {
  if (paymentType === paymentEnum.creditCardInstallment) {
    dispatchAction('InstallmentInfo', 'getInstallments');
    return;
  }
  const isFetchZingalaState = renderState('ZingalaInfo', 'fetchZingalaState') === 0;
  const isFetchZingala = paymentType === paymentEnum.ZingalaPayment && isFetchZingalaState;
  if (isFetchZingala) {
    dispatchAction('ZingalaInfo', 'getZingalaPay');
  }
};

export default {
  name: 'payment-type',
  components: {
    popOver,
  },
  props: {
    payments: Array,
  },
  computed: {
    payment,
  },
  methods: {
    isActive,
    isMobilePayment,
    fetchPayment,
    isDisabled,
  },
};
</script>
