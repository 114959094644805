/**
 * setCycleParams
 * @param {Array} cartDetails
 */
export const setCycleParams = (cartDetails) => {
  const reducer = (acc, cur) => {
    if (cur?.interval) {
      const { detailId, interval, times } = cur;
      acc.push({ detailId, interval, times });
    }
    return acc;
  };
  return cartDetails.reduce(reducer, []);
};

const infiniteTimesValue = 0;
export const checkCycleTimesType = (times) => times > infiniteTimesValue;

/**
 * showTimesLabel
 * @param {array} allTimes
 * @param {number} times
 * @return {string}
 */
export const showTimesLabel = (allTimes, times) => {
  const isItemObject = (cycle) => typeof cycle === 'object';
  const checkCycleValue = (cycle) => cycle.value === Number(times);
  const checkLabelType = (cycle) => typeof cycle.label === 'string';
  const hasLabelText = allTimes.find((cycle) => isItemObject(cycle) && checkCycleValue(cycle) && checkLabelType(cycle));
  return hasLabelText ? hasLabelText.label : '';
};

/**
 * getTimesLabel
 * @param {array} allTimes
 * @param {number} times
 * @return {string}
 */
export const getTimesLabel = (allTimes, times) => {
  const timesLabel = showTimesLabel(allTimes, times);
  return timesLabel === '' ? `${times}次` : timesLabel;
};

/**
 * getInfiniteLabelText for cycle-order-content.vue
 * @param {object} cycleDetail
 * @return {string}
 */
export const getInfiniteLabelText = (cycleDetail) => {
  const { allTimes, times } = cycleDetail;
  return getTimesLabel(allTimes, times);
};
