<template>
  <div class="credit-card-demo">
    <div class="sn-card-container">
      <div class="sn-card" :class="[flipCard, cardType]">
        <!-- 正面 -->
        <div class="sn-card-front">
          <div class="sn-card-logo sn-card-visa"></div>
          <div class="sn-card-logo sn-card-mastercard"></div>
          <div class="sn-card-logo sn-card-jcb"></div>
          <div class="sn-card-logo sn-card-amex"></div>
          <div class="sn-card-lower">
            <div class="sn-card-cvc sn-card-display">
              {{ cardCvc }}
            </div>
            <div class="sn-card-number sn-card-display" :class="focusCardNO">
              {{ cardNo }}
            </div>
            <div
              class="sn-card-expiry sn-card-display"
              data-before="month/year"
              data-after="valid thru"
              :class="focusCardExpired">
              {{ cardExpiredMonth }}/{{ cardExpiredYear }}
            </div>
          </div>
        </div>
        <!-- 背面 -->
        <div class="sn-card-back">
          <div class="sn-card-bar"></div>
          <div class="sn-card-cvc sn-card-display" :class="focusCardCVC">
            {{ cardCvc }}
          </div>
          <div class="sn-card-shiny"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultTo, concat, compose, prop, __ } from 'ramda';
import { getId } from '@/helpers/dom';
import { getState, renderState } from '@/helpers/vuex';
import {
  initialCardType,
  defaultCardType,
  cardTypeLut,
  getCardType,
  cardFormat,
} from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';

/** 信用卡號碼 */
const cardNo = function () {
  const card = `${renderState('CardInfo', 'cardNo')}`;
  const cardType = getCardType(card);
  const formatCardLength = cardType === 'Amex' ? 15 : 16;
  // 卡號未輸入部分補字 '•'
  const cardPointString = concat(
    card,
    '•'.repeat(formatCardLength - (card.length > formatCardLength ? formatCardLength : card.lenegh))
  );

  // 格式化卡號
  return cardFormat(cardPointString, cardType);
};

/** 信用卡有效期限-月 */
const cardExpiredMonth = compose(defaultTo('•'.repeat(2)), getState('CardInfo', 'cardExpiredMonth'));

/** 信用卡有效期限-年 */
const cardExpiredYear = compose(defaultTo('•'.repeat(2)), getState('CardInfo', 'cardExpiredYear'));

/** 當 [toggleCvc] 改變時，讀取 CVC 值 */
const cardCvc = compose(defaultTo('•'.repeat(3)), getId('cvc'), getState('CardInfo', 'toggleCvc'));

/** focusType === CVC 時翻轉 */
const flipCard = function () {
  const check = this.focusType === 'CVC';
  return { 'sn-card-flipped': check };
};

/** focusType === NO 正面信用卡號聚焦 */
const focusCardNO = function () {
  const check = this.focusType === 'NO';
  return { 'sn-card-focused': check };
};

/** focusType === EXPIRED 正面有效期限聚焦 */
const focusCardExpired = function () {
  const check = this.focusType === 'EXPIRED';
  return { 'sn-card-focused': check };
};

/** focusType === CVC 卡背末三碼聚焦 */
const focusCardCVC = function () {
  const check = this.focusType === 'CVC';
  return { 'sn-card-focused': check };
};

/** 取得該信用卡形態的 CSS */
const getCardCSS = compose(defaultTo(defaultCardType), prop(__, cardTypeLut), getCardType);

/** 信用卡卡別 */
const cardType = () => {
  const cardNo = renderState('CardInfo', 'cardNo');
  if (!cardNo) return initialCardType;
  return getCardCSS(cardNo);
};

export default {
  name: 'creditcard-demo',
  props: ['focusType'],
  data() {
    return {
      type: '',
    };
  },
  computed: {
    cardNo,
    cardExpiredMonth,
    cardExpiredYear,
    cardCvc,
    cardType,
    flipCard,
    focusCardNO,
    focusCardExpired,
    focusCardCVC,
  },
};
</script>

<style>
.credit-card-form,
.sn-card-container {
  float: left;
}

.credit-card-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.credit-card-form a.underline {
  margin-left: 8px;
}

.credit-card-form .number + a.underline {
  margin-left: 16px;
}

.sn-card-container {
  float: left;
  position: relative;
  z-index: 1;
  width: 336px;
  height: 200px;
  margin-left: 40px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}

@media screen and (min-width: 0\0) {
  .sn-card-container {
    display: none;
  }
}

.sn-card {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.sn-card.sn-card-flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.sn-card .sn-card-front,
.sn-card .sn-card-back {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  font-family: 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  border-radius: 10px;
  background-color: #dedfe0;
  background-image: -webkit-linear-gradient(330deg, #dedfe0, #dcddde);
  background-image: -o-linear-gradient(330deg, #dedfe0, #dcddde);
  background-image: linear-gradient(120deg, #dedfe0, #dcddde);
}

.sn-card .sn-card-front .sn-card-display,
.sn-card .sn-card-back .sn-card-display {
  color: #ffffff;
  font-weight: normal;
  opacity: 0.6;
  -webkit-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
  transition: opacity 0.4s linear;
}

.sn-card .sn-card-front .sn-card-display.sn-card-focused,
.sn-card .sn-card-back .sn-card-display.sn-card-focused {
  opacity: 1;
  font-weight: 700;
}

.sn-card .sn-card-front .sn-card-cvc,
.sn-card .sn-card-back .sn-card-cvc {
  font-size: 15px;
}

.sn-card .sn-card-front .sn-card-shiny,
.sn-card .sn-card-back .sn-card-shiny {
  position: relative;
  background: transparent url('/images/icons/icon-ccard-wafer-gray.svg') no-repeat 0 0;
}

.sn-card .sn-card-front .sn-card-logo {
  position: absolute;
  width: 88px;
  height: 44px;
  right: 24px;
  top: 20px;
  background: transparent url('/images/icons/icon-ccard-wafer-gray.svg') no-repeat 0 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.sn-card .sn-card-front .sn-card-lower {
  margin-left: 20px;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-cvc {
  visibility: hidden;
  float: right;
  position: relative;
  bottom: 5px;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-number {
  margin-top: 80px;
  margin-bottom: 32px;
  font-size: 24px;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-expiry {
  position: relative;
  padding-left: 40px;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-expiry:before,
.sn-card .sn-card-front .sn-card-lower .sn-card-expiry:after {
  font-weight: bold;
  font-size: 8px;
  white-space: pre;
  display: block;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-expiry:before {
  content: '';
  width: 72px;
  height: 12px;
  margin-bottom: 4px;
  font-size: 8px;
  text-transform: uppercase;
  background: transparent url('/images/icons/icon-img-ccard-txt-month.png') no-repeat 0 0;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-expiry:after {
  position: absolute;
  content: '';
  top: 12px;
  left: 0;
  width: 30px;
  height: 22px;
  background: transparent url('/images/icons/icon-img-ccard-txt-thru.png') no-repeat 0 0;
}

.sn-card .sn-card-front .sn-card-lower .sn-card-name {
  display: none;
}

.sn-card .sn-card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.sn-card .sn-card-back .sn-card-bar {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1b1c1d), to(#000000));
  background-image: -webkit-linear-gradient(#1b1c1d, #000000);
  background-image: -o-linear-gradient(#1b1c1d, #000000);
  background-image: linear-gradient(#1b1c1d, #000000);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%;
}

.sn-card .sn-card-back:after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% - 96px);
  height: 32px;
  top: 80px;
  left: 20px;
  background-color: #ffffff;
}

.sn-card .sn-card-back .sn-card-cvc {
  position: absolute;
  top: 84px;
  right: 20px;
  width: 40px;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.sn-card .sn-card-back .sn-card-shiny {
  height: 64px;
  margin-top: 128px;
  margin-left: 16px;
  background-position: -34px 0;
  opacity: 0.4;
}

.sn-card .sn-card-back .sn-card-shiny:after {
  content: '******************************************** ****************************';
  position: absolute;
  width: calc(100% - 88px);
  top: 8px;
  right: 20px;
  color: white;
  font-size: 8px;
  overflow: hidden;
}

.sn-card.sn-card-visa.sn-card-identified .sn-card-front,
.sn-card.sn-card-visa.sn-card-identified .sn-card-back {
  background-color: #ec7355;
  background-image: -webkit-linear-gradient(330deg, #ec7355, #eb4544);
  background-image: -o-linear-gradient(330deg, #ec7355, #eb4544);
  background-image: linear-gradient(120deg, #ec7355, #eb4544);
}

.sn-card.sn-card-visa.sn-card-identified .sn-card-logo {
  background-image: url('/images/icons/icon-ccard-visa.svg');
}

.sn-card.sn-card-mastercard.sn-card-identified .sn-card-front,
.sn-card.sn-card-mastercard.sn-card-identified .sn-card-back {
  background-color: #287ea4;
  background-image: -webkit-linear-gradient(330deg, #287ea4, #1d4da0);
  background-image: -o-linear-gradient(330deg, #287ea4, #1d4da0);
  background-image: linear-gradient(120deg, #287ea4, #1d4da0);
}

.sn-card.sn-card-mastercard.sn-card-identified .sn-card-logo {
  background-image: url('/images/icons/icon-ccard-master.svg');
}

.sn-card.sn-card-jcb.sn-card-identified .sn-card-front,
.sn-card.sn-card-jcb.sn-card-identified .sn-card-back {
  background-color: #2dadb7;
  background-image: -webkit-linear-gradient(330deg, #2dadb7, #21ac8a);
  background-image: -o-linear-gradient(330deg, #2dadb7, #21ac8a);
  background-image: linear-gradient(120deg, #2dadb7, #21ac8a);
}

.sn-card.sn-card-jcb.sn-card-identified .sn-card-logo {
  background-image: url('/images/icons/icon-ccard-jcb.svg');
}

.sn-card.sn-card-amex.sn-card-identified .sn-card-front,
.sn-card.sn-card-amex.sn-card-identified .sn-card-back {
  background-color: #9dd5f6;
  background-image: -webkit-linear-gradient(330deg, #9dd5f6, #2e77bc);
  background-image: -o-linear-gradient(330deg, #9dd5f6, #2e77bc);
  background-image: linear-gradient(120deg, #9dd5f6, #2e77bc);
}

.sn-card.sn-card-amex.sn-card-identified .sn-card-logo {
  background-image: url('/images/icons/icon-ccard-amex.svg');
}

.sn-card.sn-card-unknown .sn-card-front,
.sn-card.sn-card-unknown .sn-card-back {
  background-color: #577678;
  background-image: -webkit-linear-gradient(330deg, #577678, #455a64);
  background-image: -o-linear-gradient(330deg, #577678, #455a64);
  background-image: linear-gradient(120deg, #577678, #455a64);
}

.sn-card.sn-card-unknown .sn-card-logo {
  background-image: url('/images/icons/icon-ccard-wafer-gold.svg');
}

.sn-card.sn-card-ie-10.sn-card-flipped,
.sn-card.sn-card-ie-11.sn-card-flipped {
  -webkit-transform: 0deg;
  -ms-transform: 0deg;
  transform: 0deg;
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-front,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-back,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-back:after,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-back:after {
  left: 18%;
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-back .sn-card-cvc,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-back .sn-card-cvc {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  left: 5%;
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-back .sn-card-shiny,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-back .sn-card-shiny {
  left: 84%;
}

.sn-card.sn-card-ie-10.sn-card-flipped .sn-card-back .sn-card-shiny:after,
.sn-card.sn-card-ie-11.sn-card-flipped .sn-card-back .sn-card-shiny:after {
  left: -480%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.sn-card.sn-card-ie-10.sn-card-amex .sn-card-back,
.sn-card.sn-card-ie-11.sn-card-amex .sn-card-back {
  display: none;
}
</style>
