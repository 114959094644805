<template>
  <div>
    <payer-info :module="module"></payer-info>
  </div>
</template>

<script>
import { compose } from 'ramda';
import { applyMutation } from '@/helpers/vuex';
import validateCleanError from '@/mixins/validate-clean-error';
import PayerInfo from '@/components/desktop/step1/payer-info/child/payer-info.vue';

/** 更新 StoreInfo 所缺的 [付款人居住城市 ID] 與 [付款人居住地區 ID] */
const mounted = compose(
  applyMutation('StoreInfo', 'updateAddressTownId'),
  applyMutation('StoreInfo', 'updateAddressCityId')
);

export default {
  name: 'store-payer',
  inject: ['$validator'],
  components: {
    PayerInfo,
  },
  data: () => ({
    /** module 名稱 */
    module: 'StoreInfo',
  }),
  mounted,
  mixins: [validateCleanError],
};
</script>
