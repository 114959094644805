<template>
  <div v-if="isUseCoupon" class="css-td align right w-f6">
    <del class="price">{{ itemTotal | dollar }}</del>
    <p class="discount-price">
      <span class="label">折扣後金額</span>
      <b class="price">{{ itemDiscountTotal | dollar }}</b>
    </p>
  </div>
  <div v-else class="css-td align right w-f6">
    <b class="price">{{ itemTotal | dollar }}</b>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';

const props = {
  // 是否使用優惠券
  isUseCoupon: Boolean,
  // 商品售價
  finalPrice: Number,
  // 優惠券折扣金額
  couponDiscount: Number,
  // 商品數量
  qty: Number,
};

const computed = {
  // 購買金額
  itemTotal() {
    return this.finalPrice * this.qty;
  },
  // 折扣後購買金額
  itemDiscountTotal() {
    return Math.max(this.finalPrice * this.qty - this.couponDiscount, 0);
  },
};

const filters = {
  dollar,
};

export default {
  name: 'item-total',
  props,
  computed,
  filters,
};
</script>
