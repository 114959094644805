/** 只能輸入數字 */
export const isNumber = function (evt) {
  evt = evt || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  const check = charCode > 31 && (charCode < 48 || charCode > 57);
  if (check) {
    return evt.preventDefault();
  }
  return true;
};
