<template>
  <div class="table-pack spacing bottom">
    <div class="table-pack-head">
      <i class="icon icon-nav-cart"></i>
      <span class="spacing left sm middle">購物車明細</span>
      <span class="sub">
        共
        <b class="value">{{ cartCount }}</b>
        項商品
      </span>
      <a class="btn-switch" id="collapseBtn" data-toggle="collapse" href="#cart-list" aria-expanded="false">
        <i class="icon icon-minus"></i>
      </a>
    </div>
    <div class="table-pack-body collapse" id="cart-list">
      <div class="css-table white middle stackable">
        <div class="css-tr">
          <div class="css-th">商品名稱</div>
          <div class="css-th align center w-f4">規格</div>
          <div class="css-th align center w-f6">售價</div>
          <div class="css-th align center w-f8">數量</div>
          <div class="css-th align center w-f6">小計</div>
        </div>
        <div v-for="(item, index) in cartDetail" class="css-tr" :key="index">
          <div class="css-td">
            <a v-if="hasLink(item.pcLink)" class="img-rind pull-left" :href="`${baseUrl}${item.pcLink}`">
              <img class="thumbnail small" alt="商品圖" :src="item.masterImage" />
            </a>
            <span v-else class="img-rind pull-left">
              <img class="thumbnail small" alt="商品圖" :src="item.masterImage" />
            </span>
            <div class="info">
              <h5 class="title" v-if="hasLink(item.pcLink)">
                <a :href="`${baseUrl}${item.pcLink}`">{{ item.productName }}</a>
              </h5>
              <h5 class="title" v-else>
                {{ item.productName }}
              </h5>
              <div class="title-note tag-text-danger" v-if="item.packQuantity > 1">
                此商品為 {{ item.packQuantity }} 件組
              </div>
              <div class="discount">
                <CartTag :item="item" />
                <!-- 優惠券 -->
                <div v-if="isShowCoupon(item)" class="coupon">
                  <p class="warning">{{ couponDesc(item.couponDesc) }}</p>
                </div>
              </div>
              <!-- 週期購 -->
              <cycle-order-content :item="item" :is-mobile="false" :product-qty="item.productQty"></cycle-order-content>
            </div>
          </div>
          <!-- 規格 -->
          <div class="css-td align center w-f4">{{ item.productSpec }}</div>
          <!-- 售價 -->
          <div class="css-td align center w-f6">
            <span v-if="isShowDiscountTag(item)" class="tag tag-event-outline tag-mini tag-price"> 折扣價 </span>
            {{ item.finalPrice | dollar }}
          </div>
          <!-- 數量 -->
          <div class="css-td align center w-f8">{{ item.productQty }}</div>
          <ItemTotal
            :isUseCoupon="isUseCoupon(item.couponId)"
            :finalPrice="item.finalPrice"
            :couponDiscount="item.couponDiscount"
            :qty="item.productQty"></ItemTotal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { compose, propEq, complement } from 'ramda';
import { dollar } from '@/helpers/format/dollar';
import { getState } from '@/helpers/vuex';
import CartTag from '@/components/desktop/main/cart-tag.vue';
import ItemTotal from '@/components/desktop/main/item-total.vue';
import CycleOrderContent from '@/components/common/cycle-order-content.vue';

const url = process.env.VUE_APP_ONLINE;

/* 連結邏輯:
  - 主商品 || 加價購 => 顯示超連結
  - 贈品 || 隱賣  => 不顯示超連結
*/
const hasLink = (item) => item;

/** 是否顯示優惠券訊息 */
const isShowCoupon = compose(complement, propEq('couponId', ''));

// 顯示優惠券內容
const couponDesc = (item) => item;

// 是否有使用優惠券 for PC
const isUseCoupon = (id) => id !== '';

// 是否顯示折扣價 TAG (需為主商品且有折扣活動且未選取優惠方案才出現 tag)
const isShowDiscountTag = (item) =>
  item.cartTag === '1' && item.discountName !== '' && item.selectPricingPlan === false;

const components = {
  ItemTotal,
  CartTag,
  CycleOrderContent,
};

export default {
  name: 'cart-detail',
  components,
  data: () => ({
    baseUrl: url,
  }),
  filters: {
    dollar,
  },
  computed: {
    /** 購物車明細 */
    cartDetail: getState('CartDetail', 'cartDetail'),
    /** 購物車明細筆數 */
    cartCount: getState('CartDetail', 'cartCount'),
  },
  methods: {
    couponDesc,
    hasLink,
    isShowCoupon,
    isShowDiscountTag,
    isUseCoupon,
  },
  mounted() {
    const collapseLink = document.getElementById('collapseBtn');
    if (collapseLink !== null) {
      // eslint-disable-next-line no-unused-vars
      const myCollapseInit = new Collapse(collapseLink);
    }
  },
};
</script>
