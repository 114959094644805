<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      {{ prefix }}
    </div>
    <div class="css-td">
      <div v-if="isHasOrderName">{{ maskedName }}</div>
      <input
        v-else
        :name="errMsg"
        v-model="name"
        v-validate="'required|chinese-only'"
        placeholder="請填中文全名"
        class="input-lg"
        maxlength="7"
        autocomplete="off"
        type="text" />
      <p class="error">{{ errors.first(errMsg) }}</p>
    </div>
  </div>
</template>

<script>
import { createComputed, renderState } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';

// 有 memberName 即出現文字 ; 無則出現輸入框
const isHasOrderName = () => renderState('OrderInfo', 'isHasOrderName');

export default {
  name: 'order-name',
  inject: ['$validator'],
  props: ['prefix', 'errMsg', 'module'],
  computed: {
    isHasOrderName,
    name: createComputed('OrderInfo', 'name'),
  },
  setup(props) {
    const { maskedName } = useMask(props);
    return { maskedName };
  },
};
</script>
