<template>
  <div class="panel panel-default cart-form">
    <div class="panel-head">收件人資訊</div>
    <div class="panel-body">
      <div class="css-table pure middle">
        <recipient-employee :module="module" :states="recipientEmployeeIdStates" v-if="isEmployee"></recipient-employee>
        <recipient-name
          :module="module"
          :states="recipientNameStates"
          :prefix="'收件人'"
          :postfix="'(中文全名)'"
          :err-msg="'recipientName'">
          <span class="sub spacing left md">
            <a class="underline spacing" tabindex="-1" @blur="handleBlur" @click="copyBuyerInfo(module, orderInfoZip)">
              同訂購人資訊
            </a>
            <i v-if="isLoginUser" class="icon icon-folder-plus"></i>
            <a v-if="isLoginUser" class="underline" tabindex="-1" @blur="handleBlur" @click="showModal">
              使用收件人記事本
            </a>
          </span>
        </recipient-name>
        <recipient-mobilephone
          :module="module"
          :states="recipientMobilephoneStates"
          :name="'recipientMobilePhone'"></recipient-mobilephone>
        <recipient-localphone
          :module="module"
          :states="recipientLocalphoneStates"
          :phoneAreaName="'recipientLocalPhoneArea'"
          :phoneName="'recipientLocalPhone'"
          :phoneExtName="'recipientLocalPhoneExt'"></recipient-localphone>
        <recipient-email :module="module" :states="recipientEmailStates" :name="'Email'"></recipient-email>
        <recipient-address :module="module" :states="recipientAddressStates"></recipient-address>
      </div>
    </div>
    <address-modal></address-modal>
  </div>
</template>

<script>
import { compose, gt, __, prop } from 'ramda';
import { getAction, getState, commitMutation } from '@/helpers/vuex';
import { isLoginUser } from '@/helpers/is-from-app';
import { useMask } from '@/composables/mask';
import AddressModal from '@/components/desktop/step1/delivery-method/recipient-info/child/address-modal.vue';
import RecipientAddress from '@/components/desktop/step1/delivery-method/recipient-info/child/recipient-address.vue';
import RecipientEmail from '@/components/desktop/step1/common/common-email.vue';
import RecipientEmployee from '@/components/desktop/step1/common/common-employee-id.vue';
import RecipientLocalphone from '@/components/desktop/step1/common/common-localphone.vue';
import RecipientMobilephone from '@/components/desktop/step1/common/common-mobilephone-without-zero-order.vue';
import RecipientName from '@/components/desktop/step1/common/common-name-without-zero-order.vue';

/**  vuex 裡的 addressees 的長度大於 0 則返回 true */
const hasAddresseesList = compose(gt(__, 0), prop('length'), getState('AddressModalInfo', 'addressees'));

/** 打開 modal */
const openModal = function () {
  /** use bootstrap native 寫法 */
  const modalElem = document.getElementById('address-modal');
  if (modalElem !== null) {
    const myModal = new Modal(modalElem, { backdrop: 'static' });
    setTimeout(() => myModal.show(), 250);
  }
};

const showModal = function () {
  if (isLoginUser()) {
    commitMutation('OrderInfo', 'isClickButtons', true);
  }
  /** 如果有收件人資料或有打過[收件人記事本] API */
  if (hasAddresseesList() || this.hasLoadAddressInfo) {
    openModal();
  }
  if (!this.hasLoadAddressInfo) {
    /** load [收件人記事本] API */
    getAction('AddressModalInfo', 'getAddressees')().then(() => {
      /** 寫入已 load 過[收件人記事本] API 的紀錄 */
      commitMutation('AddressModalInfo', 'hasLoadAddressInfo', true);
      openModal();
    });
  }
};

export default {
  name: 'recipient-info',
  inject: ['$validator'],
  components: {
    RecipientEmployee,
    RecipientName,
    RecipientMobilephone,
    RecipientLocalphone,
    RecipientEmail,
    RecipientAddress,
    AddressModal,
  },
  data: () => ({
    /** module 名稱 */
    module: 'RecipientInfo',
    /** EmployeeId 所使用的 states */
    recipientEmployeeIdStates: ['employeeId'],
    /** <recipient-name/> 所使用的 states */
    recipientNameStates: ['name'],
    /** <recipient-mobilephone/> 所使用的 states */
    recipientMobilephoneStates: ['mobilePhone'],
    /** <recipient-localphone/> 所使用的 states */
    recipientLocalphoneStates: ['localPhoneArea', 'localPhone', 'localPhoneExt'],
    /** <recipient-email/> 所使用的 states */
    recipientEmailStates: ['email'],
    /** <recipient-address/> 所使用的 states */
    recipientAddressStates: ['addressCityId', 'addressCity', 'addressTownId', 'addressTown', 'addressZip', 'address'],
  }),
  computed: {
    /** 從 API 下載的 [收件人記事本] */
    addressees: getState('AddressModalInfo', 'addressees'),
    /** 從 API 取得的 [是否顯示員工編號] */
    isEmployee: getState('RecipientInfo', 'isEmployee'),
    /** 是否有打過 [收件人記事本] API */
    hasLoadAddressInfo: getState('AddressModalInfo', 'hasLoadAddressInfo'),
    // 目前訂購人的郵遞區號，供匯入收件人時檢查是否為外島用
    orderInfoZip: getState('OrderInfo', 'addressZip'),
    isLoginUser,
  },
  methods: {
    showModal,
  },
  mounted() {
    this.$validator.validate();
  },
  setup(props) {
    const { handleBlur, copyBuyerInfo } = useMask(props);
    return { handleBlur, copyBuyerInfo };
  },
};
</script>
