<template>
  <div class="css-tr">
    <div class="css-th align right w-f7">公司統編</div>
    <div class="css-td">
      <input
        type="text"
        name="order_invoice_tax_id"
        size="8"
        class="input-lg"
        autocomplete="off"
        maxlength="8"
        v-model="companyNo"
        v-validate="'required|tax-id-number'" />
      <p v-if="errors.has('order_invoice_tax_id')" class="error">* {{ errors.first('order_invoice_tax_id') }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { index } from '@/components/desktop/step1/invoice-method/paper-invoice/child/constant/company-no.constant';

export default {
  name: 'company-no',
  inject: ['$validator'],
  props: [
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
  ],
  computed: {
    /** [公司統編] state */
    companyNo: createAdvComputed(index.companyNo),
  },
};
</script>
