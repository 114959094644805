<template>
  <div class="sub underline has-inline popover-hover">
    <div class="popover-nav">(門市取貨規則)</div>
    <div class="popover">
      <h5>歡迎選擇神腦門市取貨服務</h5>
      <ul class="has-type-decimal">
        <li>商品送達門市後我們將發送簡訊與Email通知您前往取貨。</li>
        <li>收到簡訊後請攜帶身分證件與手機簡訊認證碼,至您所選擇之神腦門市取貨。</li>
        <li>
          商品配達門市後，將由神腦門市保管七天，請在指定的期限內完成取貨，逾指定期限我們將會進行退貨作業，也有可能影響到未來訂購權益。
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'store-rule',
};
</script>
