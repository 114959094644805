<template>
  <div class="container">
    <main class="row">
      <div class="alert alert-verify">
        <i class="icon icon-lock"></i>
        <p>本站採用 SSL 256 bits 安全加密機制</p>
        <p class="focus">授權處理中，請勿關閉視窗或重整頁面</p>
        <div class="progress">
          <div class="progress-bar" :style="width"></div>
        </div>
      </div>
    </main>
    <a class="btn-gotop">
      <i class="icon icon-arrow-up"></i>
      <span>TOP</span>
    </a>
  </div>
</template>

<script>
import submitOrder from '@/api/order-process';
import { setOrder } from '@/helpers/format/order-param';
import orderProcessResponse from '@/mixins/cart/finished/order-process-response';

export default {
  name: 'security',
  data: () => ({
    percent: 0,
    progress: 0,
  }),
  mixins: [orderProcessResponse],
  computed: {
    /** progressBar 寬度 */
    width() {
      return {
        width: `${this.percent}%`,
      };
    },
  },
  methods: {
    asyncLoading(res = {}, to = 100) {
      const self = this;
      // eslint-disable-next-line consistent-return
      this.progress = window.setInterval(() => {
        if (self.percent < to) {
          self.percent++;
        } else {
          window.clearInterval(self.progress);
          return res;
        }
      }, 30);
    },
  },
  /** 增加 progressBar 寬度，並切換 route */
  mounted() {
    /** [訂單成立] API */
    /** 導到 [訂單成立] 頁面 */
    this.asyncLoading({}, 80);
    submitOrder(setOrder())
      .then((res) => {
        this.asyncLoading(res, 100);
        return res;
      })
      .then((res) => this.orderProcessResponseToNext(res))
      .catch((error) => this.catchResponse(error));
  },
};
</script>
