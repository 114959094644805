<template>
  <div>
    <h5>Apple Pay付款說明</h5>
    <ul class="has-type-decimal">
      <li>限用 iOS 裝置(須軟體版本 13 以上)及 Safari 瀏覽器。</li>
      <li>使用 Apple Pay 恕不適用分期付款。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ApplePay',
};
</script>
