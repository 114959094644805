<template>
  <div>
    <div v-if="isShow" class="panel panel-default cart-form">
      <div class="panel-body">
        <span class="title spacing right middle">請選擇配送方式(限本島)：</span>
        <label v-for="(item, index) in deliveries" :key="index" class="radio-inline">
          <input v-model="delivery" :value="item.delivery" type="radio" name="delivery-type" />
          {{ item.deliveryName }}
        </label>
        <!--門市取貨規則-->
        <store-rule></store-rule>
      </div>
    </div>
    <!-- 配送公告-->
    <div class="panel panel-default cart-form">
      <div class="panel-body">
        <div class="notice">
          於疫情期間可能延遲配送時效，未配達前請您耐心等侯，詳情請見公告 (
          <a
            class="underline"
            href="https://event.senao.com.tw/senaomessage_20210525001/"
            style="color: #ef337b"
            target="_blank">
            配送作業調整
          </a>
          )。
        </div>
      </div>
    </div>
    <!-- 訂購人資訊 -->
    <order-info></order-info>
    <!--收件人資訊-->
    <!--門市取貨資訊-->
    <component :is="dynamicComponent" :key="dynamicComponent"></component>
  </div>
</template>

<script>
import { gt, compose, prop, __ } from 'ramda';
import { commitMutation, createDynamicComponent, getState, renderState } from '@/helpers/vuex';
import { componentLut } from '@/components/desktop/step1/delivery-method/constant/delivery-method.constant';
import OrderInfo from '@/components/desktop/step1/order-info/order-info.vue';
import RecipientInfo from '@/components/desktop/step1/delivery-method/recipient-info/recipient-info.vue';
import StoreInfo from '@/components/desktop/step1/delivery-method/store-info/store-info.vue';
import StoreRule from '@/components/desktop/step1/delivery-method/store-rule/store-rule.vue';

/** 當 [配送方式] > 1 時，才會顯示 [提供選擇配送方式] */
const isShow = compose(gt(__, 1), prop('length'), getState('DeliveryInfo', 'deliveries'));

/** 若 [配送方式] 只有一個 */
/** [宅配到府] 直接顯示 [收件人資訊], [門市取貨] 直接顯示 [門市取貨資訊] */
/** [所選擇配送方式] 預設為 ''，預設值 '1' 在此寫入 */
const mounted = function () {
  const deliveries = renderState('DeliveryInfo', 'deliveries');
  const delivery = deliveries.length >= 1 ? deliveries[0].delivery : '1';
  /** 寫入 [DeliveryInfo/delivery] */
  commitMutation('DeliveryInfo', 'delivery', delivery);

  /** 依照 delivery 切換 component
   * [宅配到府] 直接顯示 [收件人資訊], [門市取貨] 直接顯示 [門市取貨資訊] */
  commitMutation('DynamicComponents', 'deliveryMethod', delivery === '1' ? 'recipient-info' : 'store-info');
};

export default {
  name: 'delivery-method',
  inject: ['$validator'],
  components: {
    OrderInfo,
    RecipientInfo,
    StoreInfo,
    StoreRule,
  },
  computed: {
    /** 決定 dynamic component 要使用哪一個 component */
    dynamicComponent: getState('DynamicComponents', 'deliveryMethod'),
    /** 所有 [配送方式] */
    deliveries: getState('DeliveryInfo', 'deliveries'),
    /** 所選擇 [配送方式] */
    delivery: createDynamicComponent('DeliveryInfo', 'delivery', 'deliveryMethod', componentLut),
    isShow,
  },
  mounted,
};
</script>
