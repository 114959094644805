<template>
  <div class="total-item">
    <span class="label">小計：</span>
    <span class="price">{{ subTotal | dollar }}</span>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { getState } from '@/helpers/vuex';

export default {
  name: 'sub-total',
  filters: {
    dollar,
  },
  computed: {
    /** 小計 */
    subTotal: getState('AmountDetail', 'subTotal'),
  },
};
</script>
