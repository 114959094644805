<template>
  <div class="css-tr">
    <div class="css-th align right w-f6">
      <b class="notice">*</b>
      手機
    </div>
    <div class="css-td">
      <input
        v-if="isNotMasked"
        v-model="mobilePhone"
        v-validate="'required|mobile-phone'"
        :name="name"
        class="input-lg"
        maxlength="10"
        autocomplete="off"
        type="text" />
      <input v-else v-model="maskedMobile" class="input-lg" type="text" @focus="isNotMasked = true" />
      <p class="error">{{ errors.first(name) }}</p>
    </div>
  </div>
</template>

<script>
import { createAdvComputed } from '@/helpers/vuex';
import { useMask } from '@/composables/mask';
import { index } from '@/components/desktop/step1/common/constant/common-mobilephone.constant';

export default {
  name: 'recipient-mobilephone',
  inject: ['$validator'],
  props: [
    'name',
    /** 與 Vuex 綁定的 module 名稱 */
    'module',
    /** 與 Vuex 綁定的 state 名稱 */
    'states',
  ],
  computed: {
    /** [手機] */
    mobilePhone: createAdvComputed(index.mobilePhone),
  },
  created() {
    this.setIsNotMaskedByValue(this.mobilePhone);
  },
  setup(props) {
    const { maskedMobile, isNotMasked, setIsNotMaskedByValue } = useMask(props);
    return { maskedMobile, isNotMasked, setIsNotMaskedByValue };
  },
};
</script>
