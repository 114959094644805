<template>
  <div>
    <h5>Pi拍錢包付款說明</h5>
    <ul class="has-type-decimal">
      <li>使用Pi拍錢包恕不適用分期付款。</li>
      <li>付款額度限制：每筆交易上限為 10 萬。</li>
      <li>Pi拍錢包付款有效時間：於訂單成立後 20 分鐘內，須完成付款。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pi',
};
</script>
